/* eslint-disable */

import React, { useEffect, useRef, useState } from 'react'
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import CreatableSelect from 'react-select/creatable';
import { toast } from 'react-toastify';
import Button from '../../../Common/Button';
import App_url from '../../../Common/Constants';
import Icon from '../../../Common/Icon';
import Utils from '../../../Common/Utils';
import PrimeReactPage from '../../../Common/quill/PrimeReactPage'
import { setConfirmModalPopup, setStoreComposeMailModal } from '../../../../store/modules/modals/action';
import { PostRequestAPI } from '../../../../Api/api/PostRequest';
import { useTranslation } from '../../../../translate';


export default function ComposeEmail() {
    const { t, changeLanguage, } = useTranslation();

    const dispatch = useDispatch();
    const { customerDetails } = useSelector(App_url.customerReducers);
    const { userOutlookDetails, access_token } = useSelector(App_url.userDataReducers);
    const { lang } = useSelector(App_url.settingReducers)
    const [cidImgArray, setCidImgArray] = useState([])
    // console.log("cidImgArray", cidImgArray);

    const { ModalPopup } = useSelector(App_url.modalReducers);
    const [loader, setLoader] = useState(false);
    const [loader2, setLoader2] = useState(false);
    const [cc, setCC] = useState(false);
    const [bcc, setBCC] = useState(false);
    const [emailValue, setEmailValue] = useState([]);
    const [ccValue, setCCValue] = useState([]);
    const [bccValue, setBCCValue] = useState([]);
    const [UploadFile, setUploadFile] = useState([]);
    const [documentImageIds, setDocumentImageIds] = useState([]);


    const refs = useRef({
        refUpload: null,
        refBold: null,
        refItalic: null,
        refUnderline: null,
        refOrderedList: null,
        refUnOrderedList: null,
        refImage: null,
        refLink: null
    });

    const [formData, setFormData] = useState({
        subject: "",
        msg: "",
    })
    const [error, setError] = useState({
        subject: "",
        msg: "",
        email: "",
    })
    // console.log("ModalPopup====", ModalPopup?.data)
    useEffect(() => {
        if (ModalPopup?.show === 'COMPOSE_MAIL_MODAL') {
            if (customerDetails) {
                setEmailValue([{ label: customerDetails?.[0]?.email, value: customerDetails?.[0]?.email }]);
            } else if (ModalPopup?.data?.documentImage?.[0]?.email) {
                setEmailValue([{ label: ModalPopup?.data?.documentImage?.[0]?.email, value: ModalPopup?.data?.documentImage?.[0]?.email }])
            } else {
                setEmailValue([]);
            }
        }
        if (ModalPopup?.data?.documentImage && ModalPopup?.show === 'COMPOSE_MAIL_MODAL') {
            console.log("documentImage", ModalPopup?.data?.documentImage)
            setUploadFile(ModalPopup?.data?.documentImage);
            const imageIds = ModalPopup?.data?.documentImage?.map(doc => doc?.document_image)
            setDocumentImageIds(imageIds)
        }
        if (ModalPopup?.data?.fromPage === "viewPromotion" && ModalPopup?.show === 'COMPOSE_MAIL_MODAL') {
            console.log("promotionDocs", ModalPopup?.data?.promotionDocs)
            const promotion = ModalPopup?.data?.promotionDocs[0]
            const emailValues = promotion?.customer
                ? promotion.customer.map((item) => {
                    return { label: item?.email, value: item?.email };
                })
                : [];
            // console.log("emailValues", emailValues)
            if (promotion?.customer) {
                setEmailValue(emailValues);
            } else {
                setEmailValue([]);
            }
            // setUploadFile(ModalPopup?.data?.promotionDocs?.document);
            const imageIds = promotion?.attachment
                ? promotion.attachment
                    .filter(doc => doc?.is_inline === false) // Keep only records with is_inline === true
                : [];
            // console.log("imageIds", imageIds)
            setUploadFile(imageIds);

        }
    }, [ModalPopup?.show === 'COMPOSE_MAIL_MODAL']);


    const validation = () => {
        let val = true;

        let multiEmails = [];
        if (emailValue) multiEmails = emailValue?.map(item => item.value);
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

        if (emailValue?.length <= 0) {
            error.email = t("Please provide email");
            val = false;
        } else {
            const invalidEmails = multiEmails.filter(email => !emailPattern.test(email));
            if (invalidEmails.length > 0) {
                error.email = t("Please provide valid email addresses");
                val = false;
            }
        }
        if (formData?.subject.trim() === "") {
            error.subject = t("Please provide subject");
            val = false;
            // toast.error(t("Please provide the subject"));
        }

        setError((data) => ({
            ...data,
            ...error,
        }))
        return val;
    }

    // Convert base64 string to a Blob
    function base64ToBlob(base64, mimeType) {
        // console.log("filerArray", base64);
        const byteCharacters = atob(base64.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        return new Blob([byteArray], { type: mimeType });
    }


    // Convert base64 string to a File
    function base64ToFile(base64, filename) {
        const mimeType = base64.split(',')[0].match(/:(.*?);/)[1];
        const blob = base64ToBlob(base64, mimeType);
        return new File([blob], filename, { type: mimeType });
    }


    const replaceImageSrcs = (htmlString, newSrcs) => {
        let index = 0;

        // Regex to match base64 image data URLs
        return htmlString.replaceAll(/data:image\/[^;]+;base64,[^"]*/g, (match) => {
            const newSrc = newSrcs[index];
            index++;
            // return newSrc ? `cid:${newSrc}` : match;
            return `cid:${newSrc}`;

        });
    };

    const convertSrcQuotes = (htmlString) => {
        return htmlString.replaceAll(/src="([^"]*)"/g, (match, p1) => {
            return `src='${p1}' /`;
        });
    };

    const handleClose = async () => {
        if (validation()) {
            setLoader2(true);
            let InlineIds = ""

            // console.log("html===========", formData?.msg)

            const base64ImagesArray = [];
            console.log("base64ImagesArray", base64ImagesArray)
            const base64Regex = /<img\s+src="(data:image\/[^;]+;base64,[^"]+)"/g;
            let match;
            while ((match = base64Regex.exec(formData?.msg)) !== null) {
                base64ImagesArray.push(match[1]);
            }


            if (base64ImagesArray?.length > 0) {
                const filesArray = base64ImagesArray.map((item, index) => {
                    const base64 = item;
                    const extension = base64.split(';')[0].split('/')[1];
                    return base64ToFile(base64, `image${index + 1}.${extension}`);
                });

                console.log('filesArray', filesArray)


                try {
                    console.log("coming inside try...")
                    const uploadPromises = filesArray.map(async (file) => {
                        const formData = new FormData();
                        formData.append("file", file, file.name);
                        return PostRequestAPI(App_url.api.FILE_UPLOAD, formData, access_token, true);
                    });

                    const responses = await Promise.all(uploadPromises);
                    const successfulUploads = responses.filter(item => item?.status === 200);
                    InlineIds = successfulUploads.map(item => item.data.id);
                    // setInlineIds(inlineIds);

                } catch (error) {
                    console.error("Error during file upload:", error);
                }
            }

            if (ModalPopup?.callBackModal) {
                // console.log("inside callback...");
                const emailValues = emailValue ? emailValue.map(item => item.value) : [];
                const ccValues = ccValue ? ccValue.map(item => item.value) : [];
                const bccValues = bccValue ? bccValue.map(item => item.value) : [];
                const uploadIds = UploadFile ? UploadFile.map(item => item.id) : [];
                const replacedMsg = replaceImageSrcs(formData?.msg, InlineIds);
                const finalMsg = convertSrcQuotes(replacedMsg);
                console.log("Replaced message:", replacedMsg);

                const formData1 = {
                    email: emailValues,
                    cc: ccValues,
                    bcc: bccValues,
                    subject: formData?.subject,
                    msg: finalMsg,
                    document: documentImageIds?.length > 0 ? documentImageIds : uploadIds,
                    inline_ids: InlineIds
                };
                // console.log("formData1",formData1)
                await ModalPopup.callBackModal(formData1, ModalPopup?.data);
                CloseData();
            }

        } else {
            Object.entries(error).map(([key, value]) => {
                if (value !== "") {
                    // Replace `toast.error` with your toast function
                    toast.error(value);
                }
            });
            console.log("Validation error");
        }
    };

    // console.log("emailValuesss", emailValue);


    const EllipsisText = (text, maxLength = 13) => {
        if (text.length > maxLength) {
            return text.substring(0, maxLength) + '...';
        }
        return text;
    };
    const CloseData = async () => {
        if (ModalPopup?.callBackCancelModal) {
            await ModalPopup?.callBackCancelModal();
        }
        setCCValue([]);
        setBCCValue([]);
        setEmailValue([]);
        setCC(false);
        setBCC(false);
        setFormData({
            subject: "",
            msg: "",
        });
        setError({
            subject: "",
            msg: "",
            email: "",
        })
        setUploadFile([]);
        setCidImgArray([]);
        setLoader2(false);
        setDocumentImageIds([]);
        dispatch(setStoreComposeMailModal())
    }

    const callRemoveCC = () => {
        setCC(false);
        setCCValue([]);
    }
    const callRemoveBCC = () => {
        setBCC(false)
        setBCCValue([]);
    }

    const onChange = (e) => {
        setFormData((data) => ({
            ...data,
            [e.target.name]: e.target.value
        }))
        setError((prevData) => ({
            ...prevData,
            subject: ""
        }))
    }
    const handleSetEmailValues = (selectedOptions) => {
        const emailsList = selectedOptions?.filter((item, index) => {
            const email = Utils.ValidateEmail(item?.value);
            if (email) {
                return false
            } else {
                return true
            }
        })
        // console.log("selectedOptions", selectedOptions  )
        setEmailValue(emailsList)
        setError((prevData) => ({
            ...prevData,
            email: "",
        }))
    }
    const handleCCEmailValues = (selectedOptions) => {
        const emailsList = selectedOptions?.filter((item, index) => {
            const email = Utils.ValidateEmail(item?.value);
            if (email) {
                return false
            } else {
                return true
            }
        })
        // console.log("selectedOptions", selectedOptions  )
        // setEmailValue(emailsList)
        setCCValue(emailsList)
    }
    const handleBCCEmailValues = (selectedOptions) => {
        const emailsList = selectedOptions?.filter((item, index) => {
            const email = Utils.ValidateEmail(item?.value);
            if (email) {
                return false
            } else {
                return true
            }
        })
        // console.log("selectedOptions", selectedOptions  )
        // setEmailValue(emailsList)
        setBCCValue(emailsList)
    }


    // console.log("emailTemplate",emailTemplate)
    const handleQuillData = (messageData) => {
        setFormData((prevData) => ({
            ...prevData,
            msg: messageData
        }));
    };
    // const handleEditorChange = (e) => {
    //     const updatedMsg = e.htmlValue;
    //     setFormData((prevData) => ({
    //         ...prevData,
    //         msg: updatedMsg,
    //     }));
    //     // handleQuillData(updatedMsg);  // Pass the updated content back to the parent
    // };
    const handleFileSelect = (event) => {
        const files = event.target.files;
        handleUploadedFiles(files);

    };
    // console.log("formData",formData?.msg)
    const handleUploadedFiles = async (files) => {
        const validFileTypes = ['csv', 'xls', 'xlsx', 'pdf', 'pptx', 'png', 'jpg', 'jpeg'];

        Array.from(files).forEach(async (file) => {
            if (file) {
                const fileName = file.name;
                const fileType = fileName.split('.').pop().toLowerCase();

                if (validFileTypes.includes(fileType)) {
                    // Create a new FormData object
                    const formData = new FormData();
                    formData.append('file', file); // Append the file to the form data
                    // console.log("formDatasssssss", [...formData]);

                    try {
                        // Send the form data using your API function
                        const response = await PostRequestAPI(App_url.api.FILE_UPLOAD, formData, access_token, true);
                        console.log("responseFileUpload", response);
                        if (response?.status === 200) {
                            setUploadFile(prevFiles => [...prevFiles, response?.data]);
                            // setUploadFileIds(preFileIds => [...preFileIds, response?.data?])
                        }
                    } catch (error) {
                        console.error("File upload failed", error);
                    }
                } else {
                    toast.error("Invalid file format");
                }
            }
        });
    };
    const handleDeleteAttachment = (file) => {
        const filterUploadFile = UploadFile.filter((item) => item?.id != file?.id);
        console.log("fileUPladed", filterUploadFile);
        setUploadFile(filterUploadFile)

        if (documentImageIds?.length > 0) {
            const updatedDocumentImageIds = documentImageIds.filter((id) => id !== file.document_image);
            setDocumentImageIds(updatedDocumentImageIds);
        }
    };
    const callIcons = (attachment) => {
        const extension = attachment?.split('.').pop();
        // console.log("attachment", extension);
        if (extension === "pdf") {
            return (
                <Icon attrIcon={App_url.img.PdfIcon2} image className='sm me-1 ms-1 d-flex-center' />
            )
        } else if (extension === "jpg" || extension === "jpeg" || extension === "png") {
            return (
                <Icon attrIcon={App_url.img.DocIcon} image className='sm me-1 ms-1 d-flex-center' />
            )
        } else if (extension === "xlsx" || extension === "csv") {
            return (
                <Icon attrIcon={App_url.img.ExcelIcon} image className='sm me-1 ms-1 d-flex-center' />
            )
        } else {
            return (
                <Icon attrIcon={App_url.img.TEXTIcon} image className='sm me-1 ms-1 d-flex-center' />
            )
        }
    }
    const getEmailTeplate = () => {
        console.log("lang", lang)
        // console.log("ModalPopup?.data?.documentImage", ModalPopup?.data?.fromPage)
        let emailTemplate = ``
        if (ModalPopup?.data?.documentImage?.[0]?.fromPage === "calculation" || ModalPopup?.data?.documentImage?.[0]?.fromPage === "createOffer" || ModalPopup?.data?.documentImage?.[0]?.fromPage === "offerOverviewDetails") {
            if (lang === "de") {
                emailTemplate = `Hallo, <br/>ich hoffe, diese Nachricht erreicht Sie wohlbehalten.<br/>Vielen Dank,  
                dass Sie uns für Ihr Projekt in Betracht ziehen. Wir freuen uns auf die Möglichkeit, gemeinsam Ihre Ziele zu erreichen.
                Anbei finden Sie unser Angebot mit Details zum Leistungsumfang, Zeitrahmen und den Kosten basierend auf unseren Gesprächen. Wir haben dieses Angebot so gestaltet, 
                dass es optimal auf Ihre Anforderungen abgestimmt ist und Ihnen maximalen Mehrwert bietet.  
                <br/><br/>Bitte prüfen Sie das Angebot in Ruhe und lassen Sie uns wissen, wenn Sie Fragen haben oder Anpassungen wünschen. Wir stehen Ihnen gerne zur Verfügung, 
                um alle Aspekte weiter zu besprechen und sicherzustellen, dass das Angebot Ihren Erwartungen entspricht.<br/>Vielen Dank noch einmal für die Gelegenheit,
                und wir freuen uns auf Ihre Rückmeldung.  
                <br/><br/>Mit freundlichen Grüßen,
                `;
            }
            else {
                emailTemplate = `
                        Hi,<br/>I hope this message finds you well.
                        <br/>Thank you for considering us for your project. We are excited about the possibility of working together to achieve your goals.
                        Attached, you will find our proposal outlining the scope of work, timeline, and pricing details based on our discussions.
                        We have crafted this offer with a focus on delivering a solution that aligns with your objectives and provides maximum value.
                        <br/><br/>Please review the proposal at your convenience, and let us know if you have any questions or require adjustments.
                        We're more than happy to discuss any aspect further to ensure it meets your expectations.
                        Thank you again for the opportunity, and we look forward to your response.
                        <br/><br/>Best regards,
                        `;
            }
        }
        if (ModalPopup?.data?.fromPage === "editServicePage") {
            if (lang === "de") {
                emailTemplate = `Hallo, <br/>ich hoffe, es geht Ihnen gut.<br/>Wie gewünscht, finden Sie im Anhang die detaillierte Liste unserer angebotenen Dienstleistungen. 
                Jede Dienstleistung wurde so konzipiert, dass sie verschiedene Aspekte Ihres Projekts wertvoll und unterstützend abdeckt. Wir sind überzeugt, dass diese Optionen dazu beitragen werden, 
                Ihre Ziele effektiv und effizient zu erreichen.<br/><br/>Bitte nehmen Sie sich die Zeit, das Dokument in Ruhe zu prüfen, und lassen Sie uns wissen, wenn Sie Fragen haben oder spezifische
                Punkte weiter besprechen möchten. Wir sind hier, um Ihnen die besten Lösungen anzubieten, die Ihren Anforderungen gerecht werden.Vielen Dank für Ihre Zeit, 
                und ich freue mich darauf, bald von Ihnen zu hören.<br/><br/>Mit freundlichen Grüßen,                
                `;
            }
            else {
                emailTemplate = `
                Hi,<br/>I hope you're doing well. 
                <br/>As requested, please find attached the detailed list of services we offer. 
                Each service has been tailored to provide value and support for various aspects of your project.
                We believe these options will help achieve your objectives effectively and efficiently. <br/><br/>Please feel free to review the document at your convenience,
                and let us know if you have any questions or if there's anything specific you'd like to discuss further. 
                We're here to help you find the best solutions that meet your needs.Thank you for your time, 
                and I look forward to hearing from you soon.<br/><br/>Best regards,
                `;
            }
        }
        return emailTemplate;
    }

    if (ModalPopup?.show !== "COMPOSE_MAIL_MODAL") {
        return null
    }

    return (
        <Modal show={true} centered size='lg' className='compose-mail-dialog'>
            <Modal.Header className='compose-mail-header'>
                <h6>{t("Create mail")}</h6>
                <Icon image onClick={CloseData} attrIcon={App_url.img.RedCrossIcon} className="cursor-pointer" />
            </Modal.Header>
            <Modal.Body className='modal-body compose-mail-body'>
                <div className='from-name'>
                    <h6 className='title'>{t("From")} <span className='user-name'>{userOutlookDetails?.account?.username}</span> {t("me")}</h6>
                </div>
                <div className='to-name'>
                    <div className='title-search'>
                        <h6 className='title'>{t("To")}</h6>
                        <CreatableSelect
                            isMulti
                            placeholder={t("Enter email")}
                            // options={emailValue}
                            value={emailValue}
                            className='to-users'
                            onChange={(selectedOptions) => handleSetEmailValues(selectedOptions)}
                        />
                    </div>
                    <div className='email-cc-bcc-box'>
                        <Button onClick={() => setCC(true)} className={"ms-2 cc-btn"} size={"sm"} variant={"secondary"}>{t("CC")}</Button>
                        <Button onClick={() => setBCC(true)} className={"ms-2 bcc-btn"} size={"sm"} variant={"secondary"}>{t("BCC")}</Button>
                    </div>
                </div>
                {cc &&
                    <div className='to-name'>
                        <div className='title-search'>
                            <h6 className='title'>CC</h6>

                            <CreatableSelect
                                isMulti
                                placeholder={t("Enter cc email")}
                                value={ccValue}
                                onChange={handleCCEmailValues}
                                className='to-users'
                            />

                        </div>
                        <div className=''>
                            <Button onClick={callRemoveCC} className={"ms-2"} size={"md"} variant={"secondary"}> <Icon image className='md mb-2 mail-delete-icon' attrIcon={App_url.img.DeleteIcon} /></Button>
                        </div>
                    </div>
                }
                {bcc &&
                    <div className='to-name'>
                        <div className='title-search'>
                            <h6 className='title'>{t("BCC")}</h6>
                            {bcc &&
                                <CreatableSelect
                                    isMulti
                                    placeholder={t("Enter bcc email")}
                                    value={bccValue}
                                    onChange={handleBCCEmailValues}
                                    className='to-users'
                                />
                            }
                        </div>
                        <div className=''>
                            <Button onClick={callRemoveBCC} className={"ms-2"} size={"md"} variant={"secondary"}> <Icon image className='mb-2 mail-delete-icon' attrIcon={App_url.img.DeleteIcon} /></Button>
                        </div>
                    </div>
                }
                <textarea type="text" name='subject' onChange={onChange} value={formData?.subject || ModalPopup?.data?.promotionDocs?.[0]?.topic} className='mail-input-box' rows={1} placeholder='Subject' />
                {error?.subject && <h6 className='fs-14 compose-email-subject-error fw-4' style={{ color: "red" }}>{error?.subject}</h6>}

                <div className='prime-react-container'>
                    <PrimeReactPage handleQuillData={handleQuillData} refs={refs} initialTemplate={getEmailTeplate()} />
                </div>
                {loader && <h6 className='ms-2'>{t("Please wait while the file is uploading")}...</h6>}
                {UploadFile?.length > 0 && (
                    <React.Fragment>
                        <h6 className='attachments-text'>{t("Attachments")}</h6>
                        <div className='email-file-preview-box'>{console.log("UploadFile", UploadFile)}
                            {UploadFile.map((file, index) => (
                                <div key={index} className='email-file-preview mb-2'>
                                    <div className='email-file-name'>
                                        {callIcons(file?.name || file?.document_type)}
                                        <span className='email-attachment-name-ellipsis'>{file?.name || file?.document_name || ""}</span>
                                    </div>
                                    <div
                                        className='email-delete-icon'
                                        onClick={() => handleDeleteAttachment(file)}
                                    >
                                        <Icon
                                            image
                                            attrIcon={App_url.img.DeleteIcon}
                                            className='sm ms-4 me-1 d-flex-center'
                                        />
                                    </div>
                                </div>
                            ))}
                        </div>
                    </React.Fragment>
                )}
            </Modal.Body>
            <Modal.Footer className='p-1 d-flex  mt-2'>
                <div className='prime-header-icon-box'>
                    <input ref={el => refs.current.refUpload = el} type='file' onChange={handleFileSelect} id='input_file' hidden />
                    <Button onClick={() => refs?.current?.refUpload?.click()} variant={"secondary"} className={"prime-header-icon"} size={"sm"} ><Icon className='' attrIcon={App_url.img.AttachmentIcon} /></Button>
                    <Button onClick={() => refs?.current?.refLink?.click()} variant={"secondary"} className={"prime-header-icon"} size={"sm"} ><Icon className='' attrIcon={App_url.img.LinkIcon2} /></Button>
                    <Button onClick={() => refs?.current?.refImage?.click()} variant={"secondary"} className={"prime-header-icon"} size={"sm"} ><Icon className='' attrIcon={App_url.img.GalleryIcon} /></Button>
                    <Button onClick={() => refs?.current?.refBold?.click()} variant={"secondary"} className={"prime-header-icon bold-to-unordered"} size={"sm"} ><Icon className='' attrIcon={App_url.img.BoldIcon} /></Button>
                    <Button onClick={() => refs?.current?.refItalic?.click()} variant={"secondary"} className={"prime-header-icon bold-to-unordered"} size={"sm"} ><Icon className='' attrIcon={App_url.img.ItalicIcon} /></Button>
                    <Button onClick={() => refs?.current?.refUnderline?.click()} variant={"secondary"} className={"prime-header-icon bold-to-unordered"} size={"sm"} ><Icon className='' attrIcon={App_url.img.UnderlineIcon} /></Button>
                    <Button onClick={() => refs?.current?.refOrderedList?.click()} variant={"secondary"} className={"prime-header-icon bold-to-unordered"} size={"sm"} ><Icon className='' attrIcon={App_url.img.OrderedListIcon} /></Button>
                    <Button onClick={() => refs?.current?.refUnOrderedList?.click()} variant={"secondary"} className={"prime-header-icon bold-to-unordered"} size={"sm"} ><Icon className='' attrIcon={App_url.img.UnorderedListIcon} /></Button>
                </div>
                <div className=''>
                    <Button variant={'secondary'} className={"me-2   add-document-btn"} size={"md"} onClick={() => refs?.current?.refUpload?.click()}><span title={t("Add Document")}>{EllipsisText(t("Add Document"))}</span></Button>
                    <Button className={"me-3"} variant={"primary"} size={"md"} onClick={handleClose}>{loader2 ? t("Please wait...") : t("Send Email")}</Button>
                </div>
            </Modal.Footer>


        </Modal>
    )
}

