/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import App_url from '../Common/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { GetRequestCallAPI } from '../../Api/api/GetRequest';
import { WebSocketContext } from '../../WSContext';
import ContentLayout from '../layout/ContentLayout';
import { Breadcrumb, Card } from 'react-bootstrap';
import AnchorLink from '../Common/AnchorLink';
import { useTranslation } from '../../translate';
import Button from '../Common/Button';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Utils from '../Common/Utils';
import Icon from '../Common/Icon';
import { setStoreComposeMailModal } from '../../store/modules/modals/action';
import { PostRequestAPI } from '../../Api/api/PostRequest';
import { toast } from 'react-toastify';

const ViewPromotion = () => {
  const { t, } = useTranslation();
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()
  const promotionId = location?.state
  const [promotion, setPromotion] = useState();
  // console.log("location.state",location.state)
  const { access_token } = useSelector(App_url.userDataReducers);
  const { customerDetails } = useSelector(App_url.customerReducers);
  const { ws, send } = useContext(WebSocketContext);
  const [inlineIds, setInlineIds] = useState([]);
  const [attachments, setAttachments] = useState([]);


  const callOfferModalAPI = async () => {
    const response = await GetRequestCallAPI(App_url.api.VIEW_PROMOTION + promotionId, access_token);
    console.log("response", response);
    setPromotion(response?.data);
    const attachments = response?.data?.data?.attachment || [];

    // Arrays to hold the filtered attachments
    const inline = [];
    const uploads = [];

    // Loop through the attachments and separate them
    attachments.forEach(attachment => {
      if (attachment.is_inline) {
        inline.push(attachment);
      } else {
        uploads.push(attachment);
      }
    });

    setInlineIds(inline);
    setAttachments(uploads);
  }


  const replaceImageSrc = (htmlString, inlineIds) => {
    // Regular expression to match src="cid:..."
    const regex = /src="cid:([^"]*)"/g;

    // Replace each matched src="cid:..." with the corresponding base64 content
    const replacedHtmlString = htmlString?.replaceAll(regex, (match, cid) => {
      // Find the corresponding object in inlineIds by matching the cid

      const inlineObj = inlineIds.find(obj => obj.content_id === cid);
      if (inlineObj) {
        // Get the content bytes and content type
        const { content_type, content_bytes } = inlineObj;
        // Replace with the base64 content and appropriate content type
        return `src="data:${content_type};base64,${content_bytes}"`;
      }

      // If no matching cid is found, return the original match
      return match;
    });
    return replacedHtmlString;
  };


  const fetchImageWithAuthorization = async (url) => {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${access_token}`);
    const requestOptions = {
      method: 'GET',
      headers: headers,
      redirect: 'follow'
    };
    return fetch(url, requestOptions);
  };

  const handleDownload = async (attachment) => {
    try {
      ; console.log("attachment", attachment)

      // Replace this URL with the actual URL to fetch the attachment content
      const url = `${process.env.REACT_APP_API_DOMAIN_URL}/file/view/file/${attachment.content_id}`;
      console.log("url===", url);

      // Fetch the attachment content
      const response = await fetchImageWithAuthorization(url);
      console.log("response", response);

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Get the content as a Blob
      const blob = await response.blob();

      // Create a URL for the Blob
      const blobUrl = URL.createObjectURL(blob);

      // Create an anchor element and trigger the download
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = attachment.name || 'attachment';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      // Revoke the Blob URL after the download
      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  // console.log("promotion", promotion)
  const handleSendMailDocument = () => {
    dispatch(setStoreComposeMailModal({
      show: "COMPOSE_MAIL_MODAL",
      data: { promotionDocs: [promotion?.data],fromPage:"viewPromotion" },
      callBackModal: (mailData) => handleComposeMailAPI(mailData),
    }))
  }
  const handleComposeMailAPI = async (mailData) => {
    console.log("mailData", mailData);
    const payload = {
      subject: mailData?.subject,
      body: `<body>${mailData?.msg}</body>`,
      document: mailData?.document || [],
      inline_ids: mailData?.inline_ids || [],
      to: mailData?.email,
      cc: mailData?.cc,
      bcc: mailData?.bcc,
      customer: [customerDetails?.[0]?.id || ""],
      content_type: "Html"
    };
    console.log('payload', payload)
    const response = await PostRequestAPI(App_url.api.COMPOSE_MAIL, payload, access_token)
    console.log("responseEmail", response);
    if (response?.status === 200) {
      toast.success(response?.data?.message);
    } else {
      toast.error(response?.data?.error || response.data?.detail);
    }
  }

  useEffect(() => {
    if (ws) {
      callOfferModalAPI();
    }
  }, [ws]);

  const handleUseAgain = () => {
    console.log("promotion",promotion)
    navigate(`${App_url.link.createPromotion}`, { state:{promotion: promotion?.data} })
  }
  // console.log("promotion", promotion)
  return (
    <ContentLayout title={'View Promotion'}>
      <div className='view-promotion'>
        <Breadcrumb className="breadcrumb-main">
          <Breadcrumb.Item>
            <AnchorLink to={App_url.link.promotion}>{t("Promotions List")} </AnchorLink>
          </Breadcrumb.Item>
          <Breadcrumb.Item active>{t("View promotion")}</Breadcrumb.Item>
        </Breadcrumb>
        <Card>
          <Card.Body>
            <div className='card-body-header'>
              <h6 className='time'>{promotion !== null ? Utils.formatDateTime2(promotion?.data?.created_at, t) : ""}</h6>
              <div>
                {/* <Button className={"me-3"} variant={"primary"} onClick={(e) => handleUseAgain(e, promotion)}>{t("Use Again")}</Button> */}
                <Button className={""} variant={"primary"} onClick={() => handleSendMailDocument(promotion)}>{t("Send Mail")}</Button>
              </div>
            </div>
            <div className='promotion-body'>
              <div className='to-name'>
                <div className='title-search'>
                  <h6 className='title'>{t("Clients")}</h6>
                  <div className='search-user'>
                    {promotion?.data?.customer.map((item) => (
                      <div className='customer-name'>
                        <h6>{item?.customer_name}</h6>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {promotion === null ? (
                <h6 className='ms-3'>{t("Loading please wait")}...</h6>
              ) : (
                <div className=' mt-3'>
                  <div className='subject'>
                    <h6 className='emailtitle'> {promotion?.data?.topic}</h6>
                    <p className='mt-3 email-view-content'>

                      <div dangerouslySetInnerHTML={{ __html: replaceImageSrc(promotion?.data?.description, inlineIds) }}></div>
                    </p>
                  </div>
                  {attachments?.length > 0 ? (
                    <div className=' attachments-card'>
                      <h6 className='mb-2'>{t("Documents")}</h6>
                      <div className=' attachments-card-body'>
                        {attachments.map((attachment, index) => {
                          // You should adapt this based on the actual properties of `attachment`
                          const icon = attachment.type === 'pdf' ? App_url.img.PdfIcon2 : App_url.img.DocIcon;
                          return (
                            <div key={index} className='attachment-content-box'>
                              <div className={'attachment-icon'}>
                                <Icon image attrIcon={icon} />
                              </div>
                              <h6 className='ellipse'>{attachment.name}</h6>
                              <h6 className='text-primary ms-auto attachment-download-btn' onClick={() => handleDownload(attachment)}>{t("Download")}</h6>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  ) : (<React.Fragment />)}

                </div>
              )}
            </div>


          </Card.Body>
        </Card>
      </div>

    </ContentLayout>
  )
}

export default ViewPromotion