/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import ContentLayout from '../../layout/ContentLayout'
import Icon from '../../Common/Icon'
import App_url from '../../Common/Constants'
import { Breadcrumb, Card, Dropdown, Form, Table } from 'react-bootstrap'
import InputGroup from '../../Common/InputGroup'
import AnchorLink from '../../Common/AnchorLink'
import CustomTableTwo from '../../Common/CustomTableTwo'
import Button from '../../Common/Button'
import wsSendRequest from '../../../socketIO/wsSendRequest'
import { WebSocketContext } from '../../../WSContext'
import { useDispatch, useSelector } from 'react-redux'
import BuildingCalculationTable from './BuildingCalculationTable'
import CalculationPreviewTable from './CalculationPreviewTable'
import UtilsGetCalculations from '../UtilsGetCalculations'
import { toast } from 'react-toastify'
import Utils from '../../Common/Utils'
import { useLocation, useNavigate } from 'react-router-dom'
import { setWebsocketReqRes } from '../../../store/modules/users_data/action'
import { useTranslation } from '../../../translate'
import UtilsGetList from '../../Common/UtilsGetList'
import { setStoreGlobalLoader } from '../../../store/modules/setting_reducers/action'

const BuildingDivision = ({ data, selectedChecks }) => {
    const { t, changeLanguage } = useTranslation();

    const { ws, send } = useContext(WebSocketContext);
    const navigate = useNavigate();
    const { lang, globalLoader } = useSelector(App_url.settingReducers)
    const dispatch = useDispatch();
    const { device_id, websocketReqRes } = useSelector(App_url.userDataReducers);
    const { customerDetails } = useSelector(App_url.customerReducers);
    const { currentCategory, serviceSummary } = useSelector(App_url.calculationReducers);
    const [division, setDivision] = useState([])
    const [selectedPercentages, setSelectedPercentages] = useState([]);
    const [unselectedPercentages, setUnselectedPercentages] = useState([]);

    const location = useLocation();
    const { service } = location.state || {};

    const [formData, setFormData] = useState({
        surface_area: "",
        employee_per_hour_cost: "",
        company_surcharge: "",
        hourly_rate: 0,
        area_name: "",
        weekly_weekday_count: null,
        weekly_sunday_count: null,
        performance_value: "",

        total_surface_area: 0,
        total_monthly_weekday_hours: 0,
        total_monthly_sunday_hours: 0,
        total_monthly_hours: 0,
        total_yearly_hours: 0,

        total_monthly_weekday_cost: 0,
        total_monthly_sunday_cost: 0,
        total_monthly_cost: 0,
        total_yearly_cost: 0,
        division_array: [],

        customer_id: "",
        category_id: "",
        category_name: "",
        calculation_id: "",

        Office: { area_percentage: "65" },
        Conference: { area_percentage: "4" },
        Hallways: { area_percentage: "15" },
        "Sanitary Facilities": { area_percentage: "3" },
        "Tea Kitchen": { area_percentage: "2" },
        Entrance: { area_percentage: "2" },
        Elevator: { area_percentage: "1" },
        Store: { area_percentage: "3" },
        Stair: { area_percentage: "5" },

    });

    const [error, setError] = useState({
        employee_per_hour_cost: '',
        surface_area: '',
        company_surcharge: '',
        performance_value: '',
        room_type: '',
        type_of_cleaning: '',
        weekly_weekday_count: '',
        weekly_sunday_count: ''
    })

    // Building Division Table form Data
    const [childFormData, setChildFormData] = useState({
        Office: { area_name: "", weekly_weekday_count: 0, weekly_sunday_count: 0, performance_value: "", area_percentage: "65", isChecked: false },
        Conference: { area_name: "", weekly_weekday_count: 0, weekly_sunday_count: 0, performance_value: "", area_percentage: "4", isChecked: false },
        Hallways: { area_name: "", weekly_weekday_count: 0, weekly_sunday_count: 0, performance_value: "", area_percentage: "15", isChecked: false },
        "Sanitary Facilities": { area_name: "", weekly_weekday_count: 0, weekly_sunday_count: 0, performance_value: "", area_percentage: "3", isChecked: false },
        "Tea Kitchen": { area_name: "", weekly_weekday_count: 0, weekly_sunday_count: 0, performance_value: "", area_percentage: "2", isChecked: false },
        Entrance: { area_name: "", weekly_weekday_count: 0, weekly_sunday_count: 0, performance_value: "", area_percentage: "2", isChecked: false },
        Elevator: { area_name: "", weekly_weekday_count: 0, weekly_sunday_count: 0, performance_value: "", area_percentage: "1", isChecked: false },
        Store: { area_name: "", weekly_weekday_count: 0, weekly_sunday_count: 0, performance_value: "", area_percentage: "3", isChecked: false },
        Stair: { area_name: "", weekly_weekday_count: 0, weekly_sunday_count: 0, performance_value: "", area_percentage: "5", isChecked: false },
    });


    function convertCommaToDot(number) {
        return number?.toString()?.replace(',', '.');
    }
    const onChange = (e) => {
        let { employee_per_hour_cost, company_surcharge } = formData;
        employee_per_hour_cost = convertCommaToDot(formData?.employee_per_hour_cost);
        company_surcharge = convertCommaToDot(formData?.company_surcharge)

        if (e.target.name === "company_surcharge" && employee_per_hour_cost > 0 && e.target.value > 0) {
            const costForPercent = parseFloat(employee_per_hour_cost) * (parseFloat(e.target.value) * 0.01);
            const hourly_rate = parseFloat(employee_per_hour_cost) + parseFloat(costForPercent);
            setFormData((data) => ({
                ...data,
                hourly_rate: hourly_rate,
            }))
        }
        if (e.target.name === "employee_per_hour_cost" && company_surcharge > 0 && e.target.value > 0) {
            const costForPercent = parseFloat(e.target.value) * (parseFloat(company_surcharge) * 0.01);
            const hourly_rate = parseFloat(e.target.value) + parseFloat(costForPercent);
            setFormData((data) => ({
                ...data,
                hourly_rate: hourly_rate,
            }))
        }
        setFormData((data) => ({
            ...data,
            [e.target.name]: e.target.value,
        }));

        setError((prevData) => ({
            ...prevData,
            [e.target.name]: "",
        }))
    };



    const handleEditFormBuildingDivisionPreviewObjects = (intervalArray, type) => {
        const filterArray = intervalArray?.map((item) => {
            let filteredItem = {
                area_name: item?.area_name,
                area_percentage: item?.area_percentage,
                surface_area: item?.surface_area,
                performance_value: item?.value,
                employee_per_hour_cost: item?.employee_per_hour_cost,
                company_surcharge: item?.company_surcharge,
                hourly_rate: item?.hourly_rate,
                sunday_hourly_rate: item?.sunday_hourly_rate,

                weekly_weekday_count: item?.weekday_count,
                weekly_sunday_count: item?.sunday_count,
                yearly_weekday_frequency: item?.weekday_frequency,
                yearly_sunday_frequency: item?.sunday_frequency,

                monthly_weekday_hours: item?.weekday_hour,
                monthly_sunday_hours: item?.sunday_hour,
                monthly_hours: item?.monthly_hour,
                yearly_hours: item?.yearly_hour,

                monthly_weekday_cost: item?.weekday_cost,
                monthly_sunday_cost: item?.sunday_cost,
                monthly_cost: item?.monthly_cost,
                yearly_cost: item?.yearly_cost,

                service_summary_id: item?.service_summary_id
            };
            return filteredItem
        })

        return filterArray
    }

    // console.log("serviceSummary", serviceSummary)

    useEffect(() => {
        if (service) {
            const filteredAreaNames = serviceSummary?.service?.filter(item => item?.area_name)?.map(item => item?.area_name)
            // console.log("filteredAreaNames", filteredAreaNames);
            setFormData((prevData) => ({
                ...prevData,
                ...serviceSummary?.summary[0],
                customer_id: (serviceSummary?.service?.[0]?.customer_id === "None" || serviceSummary?.service?.[0]?.customer_id === "") ? "" : serviceSummary?.service?.[0]?.customer_id,
                category_id: serviceSummary?.service?.[0]?.category_id,
                category_name: serviceSummary?.service?.[0]?.category_name,
                calculation_id: serviceSummary?.service?.[0]?.calculation_id,
                sunday_hourly_rate: serviceSummary?.service?.[0]?.sunday_hourly_rate,

                employee_per_hour_cost: serviceSummary?.service?.[0]?.employee_per_hour_cost,
                company_surcharge: serviceSummary?.service?.[0]?.company_surcharge,
                hourly_rate: serviceSummary?.service?.[0]?.hourly_rate,

                total_surface_area: serviceSummary?.summary?.[0]?.surface_area,

                total_monthly_weekday_hours: serviceSummary?.summary?.[0]?.weekday_hour,
                total_monthly_sunday_hours: serviceSummary?.summary?.[0]?.sunday_hour,
                total_monthly_hours: serviceSummary?.summary?.[0]?.monthly_hour,
                total_yearly_hours: serviceSummary?.summary?.[0]?.yearly_hour,

                total_monthly_weekday_cost: serviceSummary?.summary?.[0]?.weekday_cost,
                total_monthly_sunday_cost: serviceSummary?.summary?.[0]?.sunday_cost,
                total_monthly_cost: serviceSummary?.summary?.[0]?.monthly_cost,
                total_yearly_cost: serviceSummary?.summary?.[0]?.yearly_cost,

                division_array: handleEditFormBuildingDivisionPreviewObjects(serviceSummary?.service)

            }))
            setChildFormData((prevData) => {
                const updatedChildFormData = { ...prevData }
                Object.keys(prevData)?.forEach((key) => {
                    const matchingService = serviceSummary?.service?.find(item => item?.area_name === key);
                    if (matchingService) {
                        updatedChildFormData[key] = {
                            ...updatedChildFormData[key],
                            area_name: matchingService.area_name,
                            weekly_weekday_count: matchingService?.weekday_count || "",
                            weekly_sunday_count: matchingService?.sunday_count || "",
                            performance_value: matchingService?.value,
                            area_percentage: matchingService?.area_percentage,
                            isChecked: true
                        }
                    }
                })
                return updatedChildFormData;
            })
        }
    }, [service?.id, serviceSummary])

    // let updatedDivisionArray;

    // // Check if the updatedChildData is checked or not
    // if (!updatedChildData.isChecked) {
    //     // Remove the object from division_array if isChecked is false
    //     updatedDivisionArray = prevFormData.division_array.filter(
    //         (item) => item.area_name !== areaName
    //     );
    // } else {
    //     // Find if the area_name already exists in division_array
    //     const existingIndex = prevFormData.division_array.findIndex(
    //         (item) => item.area_name === areaName
    //     );

    //     if (existingIndex >= 0) {
    //         // If area_name exists, replace the object with the new data
    //         updatedDivisionArray = prevFormData.division_array.map((item, index) =>
    //             index === existingIndex ? updatedChildData : item
    //         );
    //     } else {
    //         // If area_name doesn't exist, append the new data
    //         updatedDivisionArray = [...prevFormData.division_array, updatedChildData];
    //     }
    // }

    const handleChildDataChange = (areaName, updatedChildData, notSelectedTotalPercentage) => {
        console.log("updatedChildData", updatedChildData, areaName, notSelectedTotalPercentage);
        setFormData(prevFormData => {
            return {
                ...prevFormData,
                not_selected_area_percentage: notSelectedTotalPercentage,
                // division_array: updatedDivisionArray,
            };
        });
    };

    console.log("formDataDivisionArray", formData)



    const options = [
        { label: 0, value: 0 },
        { label: 1, value: 1 },
        { label: 2, value: 2 },
        { label: 3, value: 3 },
        { label: 4, value: 4 },
        { label: 5, value: 5 },
    ]
    const options2 = [
        { label: 0, value: 0 },
        { label: 1, value: 1 },

    ]

    const validation = () => {
        let val = true;

        // if (formData?.division_array.length === 0) {
        //     toast.error(t("Please select at least 1 Area name"));
        //     val = false;
        // }
        if (!formData?.surface_area) {
            error.surface_area = t("Please enter surface area")
            val = false;
        }
        if (!formData?.employee_per_hour_cost) {
            error.employee_per_hour_cost = t("Enter employee per hour cost")
            val = false;
        }
        if (!formData?.company_surcharge) {
            error.company_surcharge = t("Please enter company surcharges")
            val = false;
        }

        setError((prevData) => ({
            ...prevData,
            ...error,
        }))
        return val;
    }
    // console.log("childFormData",childFormData)
    const handleCalculate = () => {
        if (validation()) {
            if (lang === "de") {
                UtilsGetCalculations.calculateBuildingDivisionGerman({ formData, childFormData, setFormData });
            } else {
                UtilsGetCalculations.calculateBuildingDivision({ formData, childFormData, setFormData, lang });
            }
        } else {

        }
    }

    const handleBuildingDivisionObjects = (division_array) => {
        const filterArray = division_array?.map((item) => {
            if (item?.isChecked) {
                delete item?.isChecked
            }
            // if(service){
            //     item.service_summary_id =  service?.id
            // }
            return item
        })
        return filterArray
    }

    const callBuildingCalculationApi = () => {
        dispatch(setStoreGlobalLoader(service ? "update_building_calculation" : "add_building_calculation"))
        const filteredDivisionArray = handleBuildingDivisionObjects(formData?.division_array);
        const param = {
            transmit: "single",
            url: service ? "update_building_calculation" : "add_building_calculation",
            request: {
                total_surface_area: formData?.total_surface_area,
                employee_per_hour_cost: formData?.employee_per_hour_cost,
                total_monthly_sunday_cost: formData?.total_monthly_sunday_cost,
                total_monthly_weekday_cost: formData?.total_monthly_weekday_cost,
                total_monthly_cost: formData?.total_monthly_cost,
                total_yearly_cost: formData?.total_yearly_cost,
                total_monthly_sunday_hours: formData?.total_monthly_sunday_hours,
                total_monthly_weekday_hours: formData?.total_monthly_weekday_hours,
                total_monthly_hours: formData?.total_monthly_hours,
                total_yearly_hours: formData?.total_yearly_hours,
                weekday_hourly_rate: formData?.weekday_hourly_rate,
                sunday_hourly_rate: formData?.sunday_hourly_rate,
                company_surcharge: formData?.company_surcharge,
                weekday_hourly_rate: formData?.hourly_rate,
                division_array: filteredDivisionArray,
                ...(!service && { customer_id: customerDetails?.[0]?.id || "" }),
                ...(!service && { category_id: currentCategory?.id }),
                ...(!service && { category_name: currentCategory?.name }),
                ...(!service && { calculation_id: currentCategory?.calculationId ? currentCategory?.calculationId : "" }),

                ...(service && { customer_id: formData?.customer_id }),
                ...(service && { category_id: formData?.category_id }),
                ...(service && { category_name: formData?.category_name }),
                ...(service && { calculation_id: formData?.calculation_id }),
                ...(service && { sunday_hourly_rate: formData?.sunday_hourly_rate }),
                ...(service && { service_summary_id: service?.id }),
            }
        };
        send(param, device_id);
    }

    useEffect(() => {
        if (websocketReqRes?.url === "add_building_calculation" || websocketReqRes?.url === "update_building_calculation") {
            dispatch(setStoreGlobalLoader());
            if (websocketReqRes?.status === 200) {
                toast.success(websocketReqRes?.response?.msg)
                dispatch(setWebsocketReqRes())
                UtilsGetList.callGetCalculationListApi({ ws, device_id, calculationId: websocketReqRes?.response?.data?.calculation_id, lang: lang })
                navigate(`${App_url.link.calculationOverview}/${websocketReqRes?.response?.data?.calculation_id}`)
                // navigate(App_url.link.savedCalculation);
            } else {
                toast.info(websocketReqRes?.response?.msg || "error");
                dispatch(setWebsocketReqRes());
            }
        }
    }, [websocketReqRes?.url])

    return (
        <ContentLayout title={t('Building Division')}>{/* {console.log("currentCategory", customerDetails)} */}
            <div className='building building-division'>
                <Breadcrumb className="breadcrumb-main">
                    <Breadcrumb.Item>
                        <AnchorLink to={App_url.link.savedCalculation}>{t("Saved Calculation")}</AnchorLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item >
                        <AnchorLink to={`${App_url.link.selectCategory}/${customerDetails?.[0]?.id}`}>{t("Select Category")}</AnchorLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{t("Building Division")}</Breadcrumb.Item>
                </Breadcrumb>
                <div className='row m-0'>
                    <div className='col-8 p-0'>
                        <Card>
                            <div className='card-body-header'>
                                <h6 className='card-body-header-title p-3'>{t("Building Division")}</h6>
                            </div>
                            <div className='heading mt-0'>
                                <h6>{t("Customer")} : <span className='text-capitalize'>{customerDetails?.[0]?.customer_name}</span></h6>
                            </div>
                            <Card.Body className='p-2'>
                                <div className='row m-0'>
                                    <div className='col-4 p-0'>
                                        <label className='surface-area-title'>{t("Enter Building Area in Meter Square")}</label>
                                        <InputGroup
                                            placeholder={"Enter value"}
                                            name={"surface_area"}
                                            value={formData?.surface_area}
                                            error={error?.surface_area}
                                            // onChange={(e) => onChange(e, 0)}
                                            onChange={onChange}
                                            type="text"
                                            rightLabel={'m2'}
                                        // number
                                        // allowDot
                                        />
                                        <div className='hour-rate'>
                                            <div className='content-box'>
                                                <h6>{t("Hourly Rate (in € )")}</h6>
                                                <label className='mt-2'>{t("Employer Per Hour Cost")}</label>
                                                <InputGroup
                                                    placeholder={t("Enter value")}
                                                    name={"employee_per_hour_cost"}
                                                    value={formData?.employee_per_hour_cost}
                                                    formClassName={`cmp-surcharge-input-box`}
                                                    error={error?.employee_per_hour_cost}
                                                    onChange={onChange}
                                                    type="text"
                                                    rightLabel={'€'}
                                                // number
                                                // allowDot
                                                />
                                                <label className='mt-2'>{t("Company surcharges in %")}</label>
                                                <InputGroup
                                                    placeholder={t("Enter value")}
                                                    name={"company_surcharge"}
                                                    value={formData?.company_surcharge}
                                                    formClassName={`cmp-surcharge-input-box`}
                                                    error={error?.company_surcharge}
                                                    onChange={onChange}
                                                    type="text"
                                                    rightLabel={' %'}
                                                // number
                                                // allowDot
                                                />
                                            </div>

                                            <div className='text-rate'>
                                                <span>{t("Hourly Rate")}:</span>
                                                <span>€ {formData?.hourly_rate} </span>
                                            </div>

                                        </div>


                                    </div>
                                    <div className='col-8 pe-0'>
                                        <BuildingCalculationTable childFormData={childFormData} setChildFormData={setChildFormData} handleDataChange={handleChildDataChange} />
                                    </div>
                                    <div>
                                        <Button size={'sm'} variant={"primary"} className={'w-25 mt-3'} onClick={handleCalculate}>{t("Calculate")}</Button>
                                    </div>
                                </div>

                            </Card.Body>
                        </Card>

                    </div>
                    <div className='col-4  pe-0'>
                        <Card className='card building-cleaning-preview'>
                            <Card.Body>
                                <div className='preview'>
                                    <h6 className='mb-2'>{t("Preview Summary")}</h6>
                                </div>
                                <Card>
                                    <div className='p-2'>

                                        <h6>{t("Surface Area")}: {formData?.surface_area} m<sup className='fs-10'>2</sup></h6>
                                    </div>
                                    <Table>
                                        <thead className=''>
                                            <tr className='preview-thead'>
                                                <th>{t("Particular")}</th>
                                                <th>{t("Hours")}</th>
                                                <th>{t("Cost")}</th>
                                            </tr>
                                        </thead>
                                        <tbody className='table-with-radius preview-tbody'>
                                            <tr>
                                                <td>{t("Sunday")}</td>
                                                <td>{(Utils.trimDecimal(formData?.total_monthly_sunday_hours, 2) || 0)}</td>
                                                <td>€ {(Utils.trimDecimal(formData?.total_monthly_sunday_cost, 2) || 0)}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("Weekday")}</td>

                                                <td>{(Utils.trimDecimal(formData?.total_monthly_weekday_hours, 2) || 0)}</td>
                                                <td>€ {(Utils.trimDecimal(formData?.total_monthly_weekday_cost, 2) || 0)}</td>
                                            </tr>
                                            <tr>
                                                <td>{t("Monthly")}</td>
                                                <td>{(Utils.trimDecimal(formData.total_monthly_hours, 2) || 0)}</td>
                                                <td>€ {(Utils.trimDecimal(formData?.total_monthly_cost, 2) || 0)}</td>

                                            </tr>
                                            <tr>
                                                <td>{t("Yearly")}</td>
                                                <td>{(Utils.trimDecimal(formData?.total_yearly_hours, 2) || 0)}</td>
                                                <td>€ {(Utils.trimDecimal(formData?.total_yearly_cost, 2) || 0)}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Card>
                                <div className=''>
                                    <Button disable={globalLoader || formData?.total_yearly_cost === 0} onClick={callBuildingCalculationApi} size={'md'} variant={"primary"} className={'w-100  mt-5'}>{service ? `${globalLoader ? t("Please wait...") : t("Update this Service")}` : `${globalLoader ? t("Please wait...") : t("Submit this Service")}`}</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
                <div className='interval-prev '>
                    <Card className='card'>
                        <Card.Body>
                            <div className='preview'>
                                <h6>{t("Preview Summary")}</h6>
                            </div>
                            <div>
                                <CalculationPreviewTable formData={formData} />
                            </div>
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </ContentLayout>
    )
}

export default BuildingDivision