
/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import ContentLayout from '../layout/ContentLayout'
import { Breadcrumb, Card, Dropdown, Form } from 'react-bootstrap'
import Button from '../Common/Button'
import InputGroup from '../Common/InputGroup'
import Icon from '../Common/Icon'
import App_url from '../Common/Constants'
import DragDropUpload from '../Common/DragDropUpload'
import wsSendRequest from '../../socketIO/wsSendRequest'
import { WebSocketContext } from '../../WSContext'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { redirect, useLocation, useParams } from 'react-router-dom'
import { PostRequestAPI } from '../../Api/api/PostRequest'
import { useTranslation } from '../../translate'
import AnchorLink from '../Common/AnchorLink'
import UtilsGetList from '../Common/UtilsGetList'
import Utils from '../Common/Utils'

const AddExternalOffer = () => {
    const { t, changeLanguage } = useTranslation();

    const location = useLocation()
    const extOfferItem = location.state;
    const { ws, send } = useContext(WebSocketContext)
    const { websocketReqRes, } = useSelector(App_url.userDataReducers);
    const { customersList, customerDetails, citiesIndustriesPincodes } = useSelector(App_url.customerReducers);
    const { currentCategory, categoryList, frequencyList } = useSelector(App_url.calculationReducers);
    const { lang } = useSelector(App_url.settingReducers);

    const [UploadFile, setUploadFile] = useState([]);

    const [formData, setFormData] = useState({
        company_name: "",
        company_id:"",
        tender_number: "",
        service_type: "",
        industry_type: "",
        cost: "",
        date: "",
        postal_code: "",
        city: "",
        document: "",
        external_offer_id: '',
        status: 'Open'
    })
    const [error, setError] = useState({
        company_name: "",
        tender_number: "",
        service_type: "",
        industry_type: "",
        cost: "",
        date: "",
        postal_code: "",
        city: "",
        document: "",
        status: ''
    })

    const companyNameList = customersList?.data?.map((item) => {
        return {
            label: item?.company_name,
            value: item?.id,
            customer_name: item?.customer_name,
            last_name: item?.last_name,
            company_mobile: item?.company_mobile,
            city: item?.city,
            email: item?.email,
            company_address: item?.company_address,
            street_no: item?.street_no,
            postal_code: item?.postal_code,
            company_name: item?.company_name,
            customer_id: item?.id,
        };
    });


    const handleSelectCompanyName = (event) => {
        const companyId = event.target.value;
        const selectedCompany = companyNameList?.find(option => option.customer_id === companyId);
        console.log("customerDetails", selectedCompany);

        UtilsGetList.callCustomerDetailsApi({ ws: ws, customer_id: selectedCompany?.customer_id, lang: lang })

        setFormData(prev => ({
            ...prev,
            company_name: selectedCompany?.label || '',
            company_id: selectedCompany?.customer_id || '',
            first_name: selectedCompany?.customer_name || '',
            customer_id: selectedCompany?.customer_id || '',
            last_name: selectedCompany?.last_name || '',
            phone_number: selectedCompany?.company_mobile || '',
            city: selectedCompany?.city || '',
            email: selectedCompany?.email || '',
            address: selectedCompany?.company_address || '',
            street: selectedCompany?.street_no || '',
            postal_code: selectedCompany?.postal_code || '',
        }));
        setError((prevData) => ({
            ...prevData,
            company_name: "",
            first_name: "",
            customer_id: "",
            last_name: "",
            phone_number: "",
            city: "",
            email: "",
            address: "",
            street: "",
            postal_code: "",

        }))

    };

    const callCustomersListApi = (page) => {
        const param = {
            transmit: "broadcast",
            url: "customer_list",
            request: {
                status: true,
                limit: "-1",
                page_no: page,
                pincode_search: "",
                city_search: "",
                customer_type: [""],
                order_by: "-updated_at",
                industry_search: "",
            }
        };
        send(param);
    };


    useEffect(() => {
        if (ws) {
            callCustomersListApi(1);
        }
    }, [ws]);

    const validation = () => {
        let val = true;
        let error = {};

        if (!formData?.company_name) {
            error.company_name = t("Please enter company name");
            val = false;
        }
        if (!formData?.tender_number) {
            error.tender_number = t("Please enter tender number");
            val = false;
        }
        // if (!formData?.service_type) {
        //     error.service_type = t("Please select service type");
        //     val = false;
        // }
        if (!formData?.industry_type) {
            error.industry_type = t("Please select industry type");
            val = false;
        }
        if (!formData?.cost) {
            error.cost = t("Please enter cost");
            val = false;
        }
        if (!formData?.city) {
            error.city = t("Please enter city name");
            val = false;
        }
        if (!formData?.postal_code) {
            error.postal_code = t("Please enter postcode");
            val = false;
        }
        // if (!formData?.document) {
        //     error.document = t("Please choose document to upload");
        //     val = false;
        // }
        if (!formData?.date) {
            error.date = t("Please select date");
            val = false;
        }
        if (!formData?.status) {
            error.status = t("Please select status");
            val = false;
        }

        setError((prevData) => ({
            ...prevData,
            ...error
        }));

        return val;
    };
    const onSubmit = (e) => {
        e.preventDefault();
        if (validation()) {
            callAddEditExternalOfferApi();
            setUploadFile([]);
        } else {
            console.log("error in validation");
        }
    }
    const statusList = [
        { label: t("Receive"), value: t("Receive") },
        { label: t("Rejected"), value: t("Rejected") },
        { label: t("Open"), value: t("Open") },
    ]
    const handleSelect = (eventKey) => {
        const selectedOption = statusList.find(option => option.value === eventKey);
        if (selectedOption) {
            console.log("Selected weekday Frequency:", selectedOption.value);

            setFormData(prevFormData => ({
                ...prevFormData,
                status: selectedOption.value,
            }));
            setError((prevData) => ({
                ...prevData,
                status: "",
            }))
        }
    };
    const onChange = (e) => {
        setFormData((data) => ({
            ...data,
            [e.target.name]: e.target.value
        }))
        setError((prevData) => ({
            ...prevData,
            [e.target.name]: "",
        }))
    }
    const callAddEditExternalOfferApi = () => {

        const param = {
            transmit: "broadcast",
            url: extOfferItem?.id ? "offer_edit" : "offer_add",
            request: {
                company_name: formData?.company_name,
                tender_number: formData?.tender_number,
                service_type: formData?.service_type,
                industry_type: formData?.industry_type,
                cost: formData?.cost,
                date: formData?.date,
                postal_code: formData?.postal_code,
                city: formData?.city,
                document: UploadFile?.[0]?.id || "",
                customer_id: customerDetails?.[0]?.id,
                external_offer_id: extOfferItem?.id,
                // status: formData?.status
                ...(extOfferItem && {status: formData?.status}),
            },
            toast: true,
            redirect: App_url.link.externalOffers,
        };
        console.log(param)
        send(param);
    }
    console.log("extOfferItem", extOfferItem)
    useEffect(() => {
        if (extOfferItem) {
            setFormData((prevData) => ({
                ...prevData,
                ...extOfferItem,
                company_id: extOfferItem?.customer_id,
            }))
            setUploadFile([ extOfferItem?.document_list?.[0]])
        }
    }, [extOfferItem])

    const callIndustryTypeApi = (page) => {
        const param = {
            "transmit": "broadcast",
            "url": "pincode_and_city_list",
        }
        send(param);
    };
    useEffect(() => {
        if (ws) {
            callIndustryTypeApi(1);
        }
    }, [ws, websocketReqRes?.url]);

    const industryTypeOptions = citiesIndustriesPincodes?.industries?.map((item) => {

        return { label: item?.industry_type, value: item?.industry_type }
    })
    const serviceTypeOptions = categoryList?.data?.map((item) => {
        return { label: item?.category_name, value: item?.category_name }
    })

    const handleSelectIndustryType = (eventKey) => {
        setFormData(prev => ({
            ...prev,
            industry_type: eventKey
        }));
        setError((prevData) => ({
            ...prevData,
            industry_type: "",
        }))

    }

    console.log("UploadFile", UploadFile);

    return (
        <ContentLayout title={t('Add External Offers')}>
            <div className='add-external'>
                <Breadcrumb className="breadcrumb-main">
                    <Breadcrumb.Item>
                        <AnchorLink to={App_url.link.externalOffers}>{t("External offer")} </AnchorLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{extOfferItem?.id  ? t("Edit External offer ") : t("Create External offer " )}</Breadcrumb.Item>
                </Breadcrumb>
                <Card>
                    <Card.Body>
                        <div className='card-body-header'>
                            <h6 className=''>{extOfferItem?.id ?t("Edit External Offer")  :  t("Add External Offer")}</h6>
                            <Button className={""} variant={"primary"} onClick={onSubmit}>{t("Save")}</Button>
                        </div>
                        <hr className='card-body-header-hr'></hr>
                        <Form >
                            <div className='row'>
                                <div className='col-lg-4'>
                                <InputGroup
                                            label={t("Company Name")}
                                            placeholder={t("Select Company Name")}
                                            formClassName="col-12 col-md-12 mb-4 secondary-primary"
                                            type="select"
                                            select
                                            option={companyNameList}
                                            name={"company_name"}
                                            value={formData?.company_id}
                                            error={error?.company_name}
                                            onChange={handleSelectCompanyName}
                                            // isClearable
                                            disabled={extOfferItem}
                                        />
                                </div>
                                <div className='col-4'>
                                    <InputGroup
                                        label={t("Tender Number")}
                                        placeholder={t("Tender Number")}
                                        name="tender_number"
                                        value={formData?.tender_number}
                                        error={error?.tender_number}
                                        onChange={onChange}
                                    />
                                </div>
                                <div className='col-4'>
                                    <InputGroup
                                        label={t("Service Type")}
                                        placeholder={t("Select Service Type")}
                                        formClassName=" mb-3"
                                        name="service_type"
                                        option={serviceTypeOptions}
                                        value={formData?.service_type}
                                        error={error?.service_type}
                                        onChange={onChange}
                                        select
                                        type={'select'}
                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-4'>
                                    <label htmlFor=" ">{t("Select Industry type")}</label>{/*   */}
                                    <Dropdown className={`status-dropdown ${error?.industry_type ? "error" : ""}`} >
                                        <Dropdown.Toggle variant="secondary" className={`status-dropdown-toggle col-12 text-start `} id="dropdown-basic">
                                            {formData?.industry_type ? formData?.industry_type : t("Select Industry type")}
                                            <Icon image attrIcon={App_url.img.DropDownIcon} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className='dropdown-menu'>
                                            {industryTypeOptions && industryTypeOptions?.map(option => (
                                                <Dropdown.Item key={option.value}
                                                    eventKey={option.value}
                                                    onClick={() => handleSelectIndustryType(option.value)}
                                                    style={{ paddingRight: '8px' }}>
                                                    <div className='check_menu'>
                                                        <span>
                                                            {option.label}
                                                        </span>
                                                        {formData?.industry_type === option.value && (
                                                            <span className='title'>
                                                                <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                            </span>
                                                        )}
                                                    </div>
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                        {error?.industry_type && <div className='mt-1 ps-1 text-danger fs-13' >{error?.industry_type}</div>}
                                    </Dropdown>

                                </div>
                                <div className='col-4'>
                                    <InputGroup
                                        label={t("Date")}
                                        placeholder={t("Select Date")}
                                        formClassName=" mb-3"
                                        name="date"
                                        type={'date'}
                                        value={formData?.date}
                                        onChange={onChange}
                                        error={error?.date}
                                        // rightLabel={<Icon attrIcon={App_url.img.CalendarIcon} />}
                                    />
                                </div>
                                <div className='col-4'>
                                    <InputGroup
                                        label={t("Post Code")}
                                        placeholder={t("Post Code")}
                                        formClassName=" mb-3"
                                        name="postal_code"
                                        type={'text'}
                                        value={formData?.postal_code}
                                        onChange={onChange}
                                        error={error?.postal_code}

                                    />
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-4'>
                                    <InputGroup
                                        label={t("Cost")}
                                        placeholder={t("Enter Cost")}
                                        formClassName=" mb-3"
                                        name="cost"
                                        type={'text'}
                                        error={error?.cost}
                                        value={formData?.cost}
                                        onChange={onChange}
                                    />
                                </div>
                                <div className='col-4'>
                                    <InputGroup
                                        label={t("City")}
                                        placeholder={t("Select City")}
                                        formClassName=" mb-3"
                                        name="city"
                                        type={'text'}
                                        error={error?.city}
                                        value={formData?.city}
                                        onChange={onChange}
                                    />
                                </div>
                                <div className='col-4 '>
                                    <label>{t("Select Status")}</label>
                                    <Dropdown className={`status-dropdown ${error?.selectedStatus ? "error" : ""}`} >
                                        <Dropdown.Toggle variant="secondary" className='status-dropdown-toggle w-100 text-start' id="dropdown-basic">
                                            {formData?.status ? formData?.status : t('Select Status')}
                                            <Icon image attrIcon={App_url.img.DropDownIcon} />
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className='dropdown-menu'>
                                            {statusList && statusList?.map(option => (
                                                <Dropdown.Item key={option.value} eventKey={option.value}
                                                    onClick={() => handleSelect(option?.value)}
                                                    style={{ paddingRight: '8px' }}>
                                                    <div className='check_menu'>
                                                        <span>
                                                            {option.label}
                                                        </span>
                                                        {formData?.status === option.value && (
                                                            <span className='title'>
                                                                <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                            </span>
                                                        )}
                                                    </div>
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                        {error?.status && <div className='mt-1 ps-1 text-danger fs-13' >{error?.status}</div>}

                                    </Dropdown>
                                </div>

                            </div>
                            <div className='uploads'>
                                <label htmlFor="">{t("UPLOAD EXTERNAL DOCUMENT")}</label>
                                {/* <DragDropUpload text_secondary={'UPLOAD FILES'}
                                    uploadIcon={App_url.img.UploadFileIcon}
                                    className={'uploads'}
                                    onClick={() => handleUploadedFiles(files)}
                                /> */}
                                <DragDropUpload
                                    text_secondary={t('UPLOAD FILES')}
                                    className={'uploads'}
                                    error={error?.document}
                                    previewImage={false}
                                    // setPreviewImage={onChange}
                                    name={"document"}
                                    uploadIcon={App_url.img.UploadFileIcon}
                                    isMultiple={false}
                                    documentIds={(file) => setUploadFile([file])}
                                    file_type={['png', 'jpg', 'jpeg', 'pdf', 'xls', 'xlsx']}
                                />
                            </div>
                          {UploadFile?.length > 0 &&
                          
                          <div className='external-offer-preview-box'>
                          <div className='d-flex'>
                          {Utils.getIconsByExtension(UploadFile?.[0]?.name)}
                          <span className='ms-2'>{UploadFile?.[0]?.name}</span>
                          </div>
                          <Icon className={'cursor-pointer'}  onClick={()=> setUploadFile([])} attrIcon={App_url.img.DeleteIcon}/>
                      </div>
                          }
                        </Form>

                    </Card.Body>
                </Card>
            </div>

        </ContentLayout >
    )
}

export default AddExternalOffer