/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import ContentLayout from '../layout/ContentLayout'
import { Breadcrumb, Card, Dropdown, Form } from 'react-bootstrap'
import AnchorLink from '../Common/AnchorLink'
import App_url from '../Common/Constants'
import { WebSocketContext } from '../../WSContext'
import { useDispatch, useSelector } from 'react-redux'
import Button from '../Common/Button'
import InputGroup from '../Common/InputGroup'
import Icon from '../Common/Icon'
import { redirect, useLocation, useNavigate, useParams } from 'react-router-dom'
import UtilsGetList from '../Common/UtilsGetList'
import { setWebsocketReqRes } from '../../store/modules/users_data/action'
import { toast } from 'react-toastify'
import { useTranslation } from '../../translate'
import { uuidv4 } from '../Common/Utils'
import { PostRequestAPI } from '../../Api/api/PostRequest'
import { setStoreCustomersDetails } from '../../store/modules/customers/action'
import { setStoreValidationMessageModal } from '../../store/modules/modals/action'

export default function AddEditServiceList() {
    const { t, changeLanguage } = useTranslation();
    const { ws, send } = useContext(WebSocketContext);
    const { device_id, websocketReqRes, access_token } = useSelector(App_url.userDataReducers);
    const { lang } = useSelector(App_url.settingReducers);
    const { customerDetails } = useSelector(App_url.customerReducers);
    const { categoryList } = useSelector(App_url.calculationReducers);
    const { getServiceList } = useSelector(App_url.servicesReducers);
    const [loader, setLoader] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id: customerId } = useParams();

    const location = useLocation();
    // console.log("location", location);
    const serviceData = location?.state;
    // console.log("serviceData", serviceData)

    const [isOtherChecked, setIsOtherChecked] = useState(false)
    const [addedData, setAddedData] = useState([]); // Track added tasks/objects for multiple areas
    // 
    const [formData, setFormData] = useState({
        category_name: "",
        category_id: "",
        area_name: "",
        objects_name: "",
        tasks: "",
        selectedDays: {
            Monday: false,
            Tuesday: false,
            Wednesday: false,
            Thursday: false,
            Friday: false,
            Saturday: false,
            Sunday: false,
        },
        service_list: [],
        edit_state: false,
        current_edit_id: "",
    })
    const [error, setError] = useState({
        category_name: "",
        area_name: "",
        objects_name: "",
        tasks: "",
        week_days_other: "",
    })
    // useEffect(() => {
    //     if (ws) {
    //         if (customerId) {
    //             UtilsGetList.callCustomerDetailsApi({ ws, device_id, customer_id: customerId, lang: lang });
    //         }
    //     }
    // }, [ws, customerId])

    // console.log("formData", formData);

    const handleSelected = (day) => {
        console.log("data", day)
        if (!isOtherChecked) {
            setFormData((prevData) => ({
                ...prevData,
                selectedDays: {
                    ...prevData.selectedDays,
                    [day]: !prevData.selectedDays[day],
                },
            }));
            setError((prevData) => ({
                ...prevData,
                week_days_other: "",
            }))
        }
        console.log("formData?.selectedDays[day]", formData?.selectedDays)
    };


    const weekdays = [t('Monday'), t('Tuesday'), t('Wednesday'), t('Thursday'), t('Friday'), t('Saturday'), t('Sunday')];
    const shortWeekdays = [t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat'), t('sun')];

    const AreaNameOptions = [
        { label: t('Office/Sales Area'), value: t('Office/Sales Area'), },
        { label: t('Sanitary Facilities'), value: t('Sanitary Facilities') },
        { label: t('Kitchen'), value: t('Kitchen') }
    ]

    const ObjectsOptions = [
        { label: t('Waste bin'), value: t('Waste bin') },
        { label: t('Tables and desks'), value: t('Tables and desks') },
        { label: t('Doors/glass doors'), value: t('Doors/glass doors') },
        { label: t('Telephone handset'), value: t('Telephone handset') },
        { label: t('Light switch'), value: t('Light switch') },
        { label: t('Chairs,Benches,Shelves,Cupboards  etc.'), value: t('Chairs,Benches,Shelves,Cupboards  etc.') },
        { label: t('Window sills'), value: t('Window sills') },
        { label: t('Cable channels'), value: t('Cable channels') },
        { label: t('Cobwebs'), value: t('Cobwebs') },
        { label: t('Floors'), value: t('Floors') },
        { label: t('Washbasin incl. Fittings'), value: t('Washbasin incl. Fittings') },
        { label: t('Mirrors and unoccupied consoles'), value: t('Mirrors and unoccupied consoles') },
        { label: t('Towel holder'), value: t('Towel holder') },
        { label: t('Urinal'), value: t('Urinal') },
        { label: t('WC'), value: t('WC') },
        { label: t('Tile mirror in Spray area'), value: t('Tile mirror in Spray area') },
        { label: t('Floor covering'), value: t('Floor covering') },
        { label: t('Shelves, sink'), value: t('Shelves, sink') },
    ]

    const taskOptions = [
        { label: t('Empty'), value: t('Empty') },
        { label: t('Wipe with a damp cloth (if free)'), value: t('Wipe with a damp cloth (if free)') },
        { label: t('wipe with a damp cloth'), value: t('wipe with a damp cloth') },
        { label: t('Removing fingerprints'), value: t('Removing fingerprints') },
        { label: t('Wipe the dust'), value: t('Wipe the dust') },
        { label: t('Remove'), value: t('Remove') },
        { label: t('Suck'), value: t('Suck') },
        { label: t('Disinfectant cleaning'), value: t('Disinfectant cleaning') },
        { label: t('Empty and disinfect clean'), value: t('Empty and disinfect clean') },
    ]

    const CategoryOptions = categoryList?.data?.map((item) => {
        return { label: item?.category_name, value: item?.id }
    })

    const handleSelectChange = (field, value) => {

        if (field === t("tasks")) {
            const serviceExists = formData?.service_list?.find((service) => {
                const item = service?.services_offered?.find(item => item?.object === formData?.objects_name && item?.task === value && item?.parent_area_name === formData?.area_name)
                return item
            });
            if (serviceExists) {
                toast.warn("Service already added");
                return;
            }
        }
        if (field === t("objects_name")) {
            const serviceExists = formData?.service_list?.find((service) => {
                const item = service?.services_offered?.find(item => item?.object === value && item?.task === formData?.tasks && item?.parent_area_name === formData?.area_name)
                return item
            });
            if (serviceExists) {
                toast.warn("Service already added");
                return;
            }
        }

        if (field === t("category_name")) {
            setFormData(prevFormData => ({
                ...prevFormData,
                [field]: categoryList?.data?.find(item => item?.id === value)?.category_name,
                category_id: value,
                // category_name: categoryList?.data?.find(item => item?.id === value)?.category_name
            }));
            setError((prevData) => ({
                ...prevData,
                [field]: "",
            }))
        } else {
            setFormData(prevFormData => ({
                ...prevFormData,
                [field]: value
            }));
            setError((prevData) => ({
                ...prevData,
                [field]: "",
            }))
        }
    };

    const handleOtherDays = () => {
        setIsOtherChecked(!isOtherChecked);
        setFormData((prevData) => ({
            ...prevData,
            selectedDays: {
                Monday: false,
                Tuesday: false,
                Wednesday: false,
                Thursday: false,
                Friday: false,
                Saturday: false,
                Sunday: false,
            },
        }))
        setError((prevData) => ({
            ...prevData,
            week_days_other: "",
        }))
    }
    // console.log("formData.selectedDays.Monday",formData.selectedDays.Monday)

    const handleAddTask = () => {
        if (formData?.area_name && formData?.objects_name && formData?.tasks) {
            if (formData?.edit_state) {
                const updatedServiceList = formData?.service_list?.map((category) => {
                    if (category?.category_id === formData?.category_id) {
                        const updateAreaService = category?.area_services?.map((area) => {
                            if (area?.area_name === formData?.area_name) {
                                const updatedServiceOffered = area?.services_offered?.map((service) => {
                                    if (service?.id === formData?.current_edit_id) {
                                        return {
                                            ...service,
                                            object: formData.objects_name,
                                            task: formData.tasks,
                                            frequency: `${Object.values(formData?.selectedDays || {}).filter(day => t(day) === true).length}`,
                                            as_needed: isOtherChecked,
                                            mon: (formData.selectedDays.Monday || formData.selectedDays.Montag) || false,
                                            tue: (formData.selectedDays.Tuesday || formData.selectedDays.Dienstag) || false,
                                            wed: (formData.selectedDays.Wednesday || formData.selectedDays.Mittwoch) || false,
                                            thu: (formData.selectedDays.Thursday || formData.selectedDays.Donnerstag) || false,
                                            fri: (formData.selectedDays.Friday || formData.selectedDays.Freitag) || false,
                                            sat: (formData.selectedDays.Saturday || formData.selectedDays.Samstag) || false,
                                            sun: (formData.selectedDays.Sunday || formData.selectedDays.Sonntag) || false,
                                            id: formData?.current_edit_id,

                                        };
                                    }
                                    return service
                                })
                                return {
                                    ...area,
                                    services_offered: updatedServiceOffered,
                                }

                            }
                            return area;
                        })
                        return {
                            ...category,
                            area_services: updateAreaService,
                        }
                    }
                    return category
                })
                console.log("updatedData", updatedServiceList)
                setFormData((prevData) => ({
                    ...prevData,
                    service_list: updatedServiceList,
                    objects_name: "",  // Clear object input
                    tasks: "",  // Clear task input
                    selectedDays: {
                        Monday: false,
                        Tuesday: false,
                        Wednesday: false,
                        Thursday: false,
                        Friday: false,
                        Saturday: false,
                        Sunday: false,
                    },
                    edit_state: false,
                    current_edit_id: "",
                }))
                setIsOtherChecked(false);
            }
            else {
                const newServiceOffered = {
                    parent_area_name: formData.area_name,
                    object: formData.objects_name,
                    task: formData.tasks,
                    frequency: `${Object.values(formData?.selectedDays || {}).filter(day => t(day) === true).length}`,
                    as_needed: isOtherChecked,
                    mon: (formData.selectedDays.Monday || formData.selectedDays.Montag) || false,
                    tue: (formData.selectedDays.Tuesday || formData.selectedDays.Dienstag) || false,
                    wed: (formData.selectedDays.Wednesday || formData.selectedDays.Mittwoch) || false,
                    thu: (formData.selectedDays.Thursday || formData.selectedDays.Donnerstag) || false,
                    fri: (formData.selectedDays.Friday || formData.selectedDays.Freitag) || false,
                    sat: (formData.selectedDays.Saturday || formData.selectedDays.Samstag) || false,
                    sun: (formData.selectedDays.Sunday || formData.selectedDays.Sonntag) || false,
                    id: uuidv4(),
                };

                setFormData((prevFormData) => {
                    // Find the category in the service list
                    const categoryIndex = prevFormData.service_list.findIndex(
                        (category) => category.category_name === formData?.category_name
                    );

                    if (categoryIndex > -1) {
                        // Category exists, now find the area in the area's services
                        const category = prevFormData.service_list[categoryIndex];
                        const areaIndex = category.area_services.findIndex(
                            (area) => area.area_name === formData?.area_name
                        );

                        if (areaIndex > -1) {
                            // Area exists, append the new service to the services_offered array
                            const updatedServiceList = [...prevFormData.service_list];
                            updatedServiceList[categoryIndex] = {
                                ...category,
                                area_services: category.area_services.map((area, idx) =>
                                    idx === areaIndex
                                        ? {
                                            ...area,
                                            services_offered: [
                                                ...area.services_offered,
                                                newServiceOffered
                                            ]
                                        }
                                        : area
                                ),
                            };

                            return {
                                ...prevFormData,
                                service_list: updatedServiceList,
                                objects_name: "",  // Clear object input
                                tasks: "",  // Clear task input
                                selectedDays: {
                                    Monday: false,
                                    Tuesday: false,
                                    Wednesday: false,
                                    Thursday: false,
                                    Friday: false,
                                    Saturday: false,
                                    Sunday: false,
                                },
                            };
                        } else {
                            // Area does not exist, add new area with the new service
                            const updatedServiceList = [...prevFormData.service_list];
                            updatedServiceList[categoryIndex] = {
                                ...category,
                                area_services: [
                                    ...category.area_services,
                                    {
                                        area_name: formData?.area_name,
                                        services_offered: [newServiceOffered],
                                    },
                                ],
                            };

                            return {
                                ...prevFormData,
                                service_list: updatedServiceList,
                                objects_name: "",  // Clear object input
                                tasks: "",  // Clear task input
                                selectedDays: {
                                    Monday: false,
                                    Tuesday: false,
                                    Wednesday: false,
                                    Thursday: false,
                                    Friday: false,
                                    Saturday: false,
                                    Sunday: false,
                                },
                            };
                        }
                    } else {
                        // Category does not exist, create new category with area and service
                        const newCategory = {
                            category_name: formData?.category_name,
                            category_id: formData?.category_id,
                            area_services: [
                                {
                                    area_name: formData?.area_name,
                                    services_offered: [newServiceOffered],
                                },
                            ],
                        };

                        return {
                            ...prevFormData,
                            service_list: [...prevFormData.service_list, newCategory],
                            objects_name: "",  // Clear object input
                            tasks: "",  // Clear task input
                            selectedDays: {
                                Monday: false,
                                Tuesday: false,
                                Wednesday: false,
                                Thursday: false,
                                Friday: false,
                                Saturday: false,
                                Sunday: false,
                            },
                        };
                    }
                });
                setIsOtherChecked(false);
            }
        }
    };
    // console.log(formData.selectedDays.Monday)
    // console.log("formdata", formData)

    const validation = () => {
        let val = true;
        let error = {};

        if (!formData?.category_name) {
            error.category_name = t("Please select category");
            val = false;
        }

        if (!formData?.area_name) {
            error.area_name = t("Please select area name");
            val = false;
        }

        if (!formData?.objects_name) {
            error.objects_name = t("Please select object name");
            val = false;
        }
        if (!formData?.tasks) {
            error.tasks = t("Please select task");
            val = false;
        }
        const allDaysUnchecked = Object.values(formData.selectedDays).every(day => day === false);

        if (allDaysUnchecked && !isOtherChecked) {
            error.week_days_other = "Please select at least one day or select other";
            val = false;
        }

        setError((prevData) => ({
            ...prevData,
            ...error
        }));

        return val;
    };
    const handleCreateRow = () => {
        const isValid = validation();
        if (!isValid) {
            console.log("Validation failed.");
            return;
        }
        handleAddTask();
    }


    const callCategoryListApi = (page) => {
        const param = {
            transmit: "broadcast",
            url: "category_list",
            request: { limit: "10", page_no: page, order_by: "-updated_at", }
        };
        send(param);
    };
    const callGetSingleServiceApi = () => {
        const param = {
            transmit: "broadcast",
            url: "get_los_service",
            request: { service_id: serviceData?.id }
        };
        send(param);
    };
    useEffect(() => {
        if (ws) {
            callCategoryListApi(1);
        }
        if (serviceData?.id) {
            callGetSingleServiceApi();
        }
    }, [ws, serviceData?.id])


    const callCreateEditService = () => {
        const param = {
            url: (serviceData?.id && !serviceData?.fromPage) ? "create_service" : "create_service",
            transmit: "broadcast",
            request: {
                service_list: formData?.service_list,
                service_id: (!serviceData?.fromPage && serviceData?.id) ? serviceData?.id : "",
                customer_id: customerDetails?.[0]?.id || "",
                customer_name: customerDetails?.[0]?.customer_name || "",
                ...(serviceData && { customer_id: getServiceList?.[0]?.customer_id || "" }),
                ...(serviceData && { customer_name: getServiceList?.[0]?.customer_name || "" }),
            },
            // toast: true,
            // redirect: App_url?.link?.savedServiceList
        };
        send(param);
    }

    // console.log("getServiceList", serviceData);

    useEffect(() => {
        if (websocketReqRes?.url === "create_service" || websocketReqRes?.url === "update_los_service") {
            if (websocketReqRes?.status === 200) {
                toast.success(websocketReqRes?.response?.msg)
                dispatch(setWebsocketReqRes())
                navigate(App_url.link.savedServiceList);
            } else {
                toast.info(websocketReqRes?.response?.msg || "error");
                dispatch(setWebsocketReqRes());
            }
        }
    }, [websocketReqRes?.url])

    const onSubmit = () => {
        if (formData?.service_list?.length > 0) {
            callCreateEditService();
        } else {
            console.log("error in validation")
            toast.error("Please add at least one service")
        }
    }

    // let listServiceData = getServiceList?.[0] || "";
    const handleNavigate = (customerId) => {
        navigate(`${App_url.link.ProspectsEdit}/${customerDetails[0]?.id}`, { state: { fromPage: "addServiceList", pageId: getServiceList?.[0]?.id, customerId: customerId, serviceData: getServiceList?.[0] } })
    }
    useEffect(() => {
        if (customerId && serviceData) {
            UtilsGetList.callCustomerDetailsApi({ ws, customer_id: serviceData?.customer_id, lang: lang })
        }
    }, [])

    // console.log("serviceData?.id",serviceData)
    const handleAddOffer = async (serviceId, customerId, serviceData) => {
        if (customerDetails?.[0]?.email === "" || customerDetails?.[0]?.email === null) {
            const hasMissingFields =
                customerDetails?.[0]?.email === null || customerDetails?.[0]?.email === "" ||
                customerDetails?.[0]?.customer_name === null || customerDetails?.[0]?.customer_name === "" ||
                customerDetails?.[0]?.company_address === null || customerDetails?.[0]?.company_address === "" ||
                customerDetails?.[0]?.street_no === null || customerDetails?.[0]?.street_no === "" ||
                customerDetails?.[0]?.city === null || customerDetails?.[0]?.city === "" ||
                customerDetails?.[0]?.postal_code === null || customerDetails?.[0]?.postal_code === "" ||
                (customerDetails?.[0]?.company_mobile === null || customerDetails?.[0]?.company_mobile === "") &&
                (customerDetails?.[0]?.mobile === null || customerDetails?.[0]?.mobile === "");

            const missingFieldsMessage = hasMissingFields
                ? t("The information of selected customer is incomplete, please update customer information.")
                : "";

            // UtilsGetList.callCustomerDetailsApi({ ws, customer_id: customerId, lang: lang });
            dispatch(setStoreValidationMessageModal({
                show: "VALIDATION_MESSAGE_MODAL",
                title: t("Validation Message"),
                description: missingFieldsMessage,
                callBackModal: () => handleNavigate(serviceId, customerId, serviceData),
            }));
        } else {
            if (serviceData?.customer_id) {
                setLoader(true);
                dispatch(setStoreCustomersDetails())
                UtilsGetList.callCustomerDetailsApi({ ws, customer_id: serviceData?.customer_id, lang: lang })
                const payload = {
                    service_id: serviceData?.id,
                    customer_id: serviceData?.customer_id,
                }
                const response = await PostRequestAPI(App_url.api.SERVICE_PDF, payload, access_token);
                setLoader(false)
                console.log("response", response);
                if (response?.status === 201 || response?.status === 200) {
                    const viewFileUrl = response?.data?.file_view;
                    navigate(`${App_url.link.previewServiceList}/${serviceData?.customer_id}`, { state: { view_file_url: viewFileUrl, fromPage: "editServicePage" } });
                } else {
                    toast.error(response?.data?.error || response.data?.detail || "error...");
                }
            }
            else {
                navigate(App_url?.link?.selectCustomerServices, { state: { serviceId: serviceData?.id, serviceData: serviceData } })
            }
        }
        // }
    }
    // console.log(getServiceList[0]?.category_id[0])
    useEffect((customerId) => {
        // UtilsGetList.callCustomerDetailsApi({ ws, customer_id: customerId, lang: lang });
        const categoryIds = getServiceList[0]?.service_list?.map(item => item?.category_id)
        const categoryNames = getServiceList[0]?.service_list?.map(item => item?.category_name)
        // console.log("categoryNames", categoryNames);
        if (serviceData) {
            setFormData((prevData) => ({
                ...prevData,
                ...getServiceList[0],
                // category_id: categoryIds,
                category_name: ""
            }))
        }
    }, [serviceData, getServiceList])


    const handleDeleteServiceOffered = (categoryId, areaName, service) => {
        console.log("serviceList", formData?.service_list);

        const updatedServiceList = formData?.service_list?.map((category) => {
            if (category?.category_id === categoryId) {
                // Iterate over all area_services to find the matching area

                const updatedAreaService = category?.area_services?.map((area) => {
                    if (areaName === area?.area_name) {

                        const updatedServiceOffered = area?.services_offered?.filter((serv) => serv?.id != service?.id)
                        return {
                            ...area,
                            services_offered: updatedServiceOffered
                        }
                    }
                    return area;
                })

                return {
                    ...category,
                    area_services: updatedAreaService,
                }
            }

            return category; // No matching category, return as is
        });

        console.log("updatedServiceList", updatedServiceList);

        // Update the formData with the new service list
        setFormData((prevFormData) => ({
            ...prevFormData,
            service_list: updatedServiceList,
        }));
    };

    const handleSetFormDataToEditServiceOffered = (categoryId, areaName, service) => {
        console.log("service", service);
        setFormData((prevData) => ({
            ...prevData,
            category_name: categoryList?.data?.find(item => item?.id === categoryId)?.category_name,
            category_id: categoryId,
            area_name: areaName,
            objects_name: service?.object,
            tasks: service?.task,
            selectedDays: {
                Monday: service?.mon,
                Tuesday: service?.tue,
                Wednesday: service?.wed,
                Thursday: service?.thu,
                Friday: service?.fri,
                Saturday: service?.sat,
                Sunday: service?.sun,
            },
            edit_state: true,
            current_edit_id: service?.id,
        }))
        if (service?.as_needed) {
            setIsOtherChecked(true);
        }

    }

    // console.log("formData", formData);


    return (
        <ContentLayout>
            <div className='services'>
                <Breadcrumb className="breadcrumb-main">
                    <Breadcrumb.Item>
                        <AnchorLink to={App_url.link.services}>{t("List of Services")}  </AnchorLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <AnchorLink onClick={() => navigate(App_url.link.savedServiceList)}>{t("Saved Service List")}</AnchorLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active> {serviceData?.id ? t("Edit Service List") : t("Add Service List")}</Breadcrumb.Item>
                </Breadcrumb>
                <div className='edit-service'>
                    <Card>
                        <div className='card-body-header'>
                            <h6 className='card-body-header-title '> {serviceData?.id ? t("Edit Service List") : t("Add Service List")}</h6>
                            <div>
                                {(serviceData?.id && !serviceData?.fromPage) ?
                                    <>
                                        <Button onClick={onSubmit} className={"me-2"} size={'md'} variant={"grey"}> {t("Update Service")}</Button>
                                        <Button disable={loader} onClick={() => handleAddOffer(serviceData?.id, serviceData?.customer_id, serviceData)} className={"me-2"} size={'md'} variant={"primary"}>{loader ? t("Please wait...") : t("Send to Customer")}</Button>
                                    </>
                                    : <Button onClick={onSubmit} className={"me-2"} size={'md'} variant={"grey"}> {t("Save Service")}</Button>
                                }
                                {/* <Button className={""} size={'md'} variant={"primary"}>Send to Customer</Button> */}
                            </div>
                        </div>
                        {/* <div className='heading mt-0'>
                            <h6>Customer : <span className='text-capitalize'>{customerDetails?.[0]?.customer_name}</span></h6>
                            </div> */}
                        <div className='px-3 mb-2'>
                            <hr className='m-0 px-2' />
                        </div>
                        <div className='service-data'>

                            <Form>
                                <div className='row'>
                                    <div className='col'>
                                        <label>{t("Service Category")}</label>
                                        <Dropdown className={`status-dropdown  ${error?.category_name ? "error" : ""}`} onSelect={(value) => handleSelectChange('category_name', value)}>
                                            <Dropdown.Toggle disabled={formData?.edit_state} variant="secondary" className='status-dropdown-toggle col-12 text-start ' id="dropdown-basic">
                                                {formData?.category_name ? t(formData?.category_name) : t("Select service category")}
                                                <Icon image attrIcon={App_url.img.DropDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dropdown-menu'>
                                                {CategoryOptions && CategoryOptions.map(option => (
                                                    <Dropdown.Item key={option.value} eventKey={option.value} style={{ paddingRight: '8px' }}>
                                                        <div className='check_menu'>
                                                            <span>
                                                                {option.label}
                                                            </span>
                                                            {formData?.category_name === option.value ? (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                                </span>
                                                            ) : (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.RadioCircleIcon} />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                            {error?.category_name && <div className='mt-1 ps-1 text-danger fs-13' >{error?.category_name}</div>}
                                        </Dropdown>
                                    </div>
                                    <div className='col'>
                                        <label>{t("Select Area Name")}</label>
                                        <Dropdown className={`status-dropdown  ${error?.area_name ? "error" : ""}`}
                                            onSelect={(value) => handleSelectChange('area_name', value)}>
                                            <Dropdown.Toggle disabled={formData?.edit_state} variant="secondary" className='status-dropdown-toggle col-12 text-start' id="dropdown-basic">
                                                {formData?.area_name ? formData?.area_name : t("Select area name")}
                                                <Icon image attrIcon={App_url.img.DropDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dropdown-menu'>
                                                {AreaNameOptions && AreaNameOptions.map(option => (
                                                    <Dropdown.Item key={option.value} eventKey={option.value} style={{ paddingRight: '8px' }}>
                                                        <div className='check_menu'>
                                                            <span>
                                                                {option.label}
                                                            </span>
                                                            {formData?.area_name === option.value ? (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                                </span>
                                                            ) : (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.RadioCircleIcon} />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                            {error?.area_name && <div className='mt-1 ps-1 text-danger fs-13' >{error?.area_name}</div>}
                                        </Dropdown>
                                    </div>
                                    <div className='col'>
                                        <label>{t("Objects")}</label>
                                        <Dropdown className={`status-dropdown ${error?.objects_name ? "error" : ""}`}
                                            onSelect={(value) => handleSelectChange('objects_name', value)}>
                                            <Dropdown.Toggle variant="secondary" className='status-dropdown-toggle col-12 text-start' id="dropdown-basic">
                                                {formData?.objects_name ? formData?.objects_name : t("Select object name")}
                                                <Icon image attrIcon={App_url.img.DropDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dropdown-menu'>
                                                {ObjectsOptions && ObjectsOptions.map(option => (
                                                    <Dropdown.Item key={option.value} eventKey={option.value} style={{ paddingRight: '8px' }}>
                                                        <div className='check_menu'>
                                                            <span>
                                                                {option.label}
                                                            </span>
                                                            {formData?.objects_name === option.value ? (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                                </span>
                                                            ) : (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.RadioCircleIcon} />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                            {error?.objects_name && <div className='mt-1 ps-1 text-danger fs-13' >{error?.objects_name}</div>}
                                        </Dropdown>
                                    </div>
                                    <div className='col'>
                                        <label>{t("Task")}</label>
                                        <Dropdown className={`status-dropdown  ${error?.tasks ? "error" : ""}`}
                                            onSelect={(value) => handleSelectChange('tasks', value)}>
                                            <Dropdown.Toggle variant="secondary" className='status-dropdown-toggle col-12 text-start' id="dropdown-basic">
                                                {formData?.tasks ? formData?.tasks : t("Select task")}
                                                <Icon image attrIcon={App_url.img.DropDownIcon} />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu className='dropdown-menu'>
                                                {taskOptions && taskOptions.map(option => (
                                                    <Dropdown.Item key={option.value} eventKey={option.value} style={{ paddingRight: '8px' }}>
                                                        <div className='check_menu'>
                                                            <span>
                                                                {option.label}
                                                            </span>
                                                            {formData?.tasks === option.value ? (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.Radiobutton2} />
                                                                </span>
                                                            ) : (
                                                                <span className='title'>
                                                                    <Icon image attrIcon={App_url.img.RadioCircleIcon} />
                                                                </span>
                                                            )}
                                                        </div>
                                                    </Dropdown.Item>
                                                ))}
                                            </Dropdown.Menu>
                                            {error?.tasks && <div className='mt-1 ps-1 text-danger fs-13' >{error?.tasks}</div>}
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className='weekdays mb-3'>
                                    {weekdays.map((day, index) => (
                                        <div key={index} className="day-selector" >
                                            <h6>{day}</h6>
                                            {formData.selectedDays[day] && !isOtherChecked ? (
                                                <span className="weekday-icon" onClick={() => handleSelected(day)}>
                                                    <Icon image attrIcon={App_url.img.GreenRadioIcon} />
                                                </span>
                                            ) : (
                                                <span className="title" onClick={() => handleSelected(day)}>
                                                    <Icon image attrIcon={App_url.img.RadioCircleIcon} />
                                                </span>
                                            )}
                                        </div>
                                    ))}
                                    <div className='day-selector'>
                                        <h6 className='other  fw-5 fs-13 '>{t("Other")}</h6>
                                        <InputGroup
                                            type="switch"
                                            name={"status"}
                                            className={'custom-switch'}
                                            checked={isOtherChecked}
                                            onCheck={() => handleOtherDays()} />
                                    </div>
                                </div>
                                {error?.week_days_other && <div className='mt-1 ps-1 text-danger fs-13' >{error?.week_days_other}</div>}

                            </Form>
                        </div>

                    </Card>

                    {/* =============================== */}

                    <div className='listService'>
                        <Card>
                            <Card.Body>
                                <div className='card-body-header pt-0'>
                                    <h6 className='card-body-header-title '>{t("List of Service")}</h6>
                                    <Button className={""} size={'sm'} variant={"outline-primary"} onClick={handleCreateRow}> {formData?.edit_state ? t("Update List") : t("+Add in List")} </Button>
                                </div>
                                {formData?.service_list?.length > 0 &&
                                    formData?.service_list?.map((category, categoryIndex) => (
                                        <div key={categoryIndex} className="custom-table">
                                            <table className="table table-bordered mb-0">
                                                <thead>
                                                    {categoryIndex === 0 && (
                                                        <tr>
                                                            <th className='header-1 top-left'>{t("Action")}</th>
                                                            <th className='header-1'>{t("Object")}</th>
                                                            <th className='header-1'>{t("Task")}</th>
                                                            <th className='header-1'>{t("Frequency")}</th>
                                                            {weekdays.map((day) => (
                                                                <th key={day} className={categoryIndex === 0 && day === t('Sunday') ? 'top-right header-1' : 'header-1'}>
                                                                    {t(day)}
                                                                </th>
                                                            ))}
                                                        </tr>
                                                    )}
                                                </thead>

                                                <thead>
                                                    <tr>
                                                        <th colSpan="11" className='header-2'>{category.category_name}</th>
                                                    </tr>
                                                </thead>

                                                {category?.area_services?.map((area, areaIndex) => (
                                                    <React.Fragment key={areaIndex}>
                                                        <thead>
                                                            <tr>
                                                                <th colSpan="11" className='header-3'>{area?.area_name}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>{/* {console.log("area?.services_offered", area?.services_offered)} */}
                                                            {area?.services_offered?.map((service, serviceIndex) => (
                                                                <tr key={serviceIndex}>
                                                                    <td className='text-center add-edit-bg'>
                                                                        <Icon onClick={() => handleSetFormDataToEditServiceOffered(category?.category_id, area?.area_name, service)} className="me-2 cursor-pointer" attrIcon={App_url.img.EditIcon} />
                                                                        <Icon onClick={() => handleDeleteServiceOffered(category?.category_id, area?.area_name, service)} className="cursor-pointer" attrIcon={App_url.img.DeleteIcon} />
                                                                    </td>

                                                                    <td className={`object-td 
                                                                        ${t(service?.parent_area_name) === (AreaNameOptions?.[0]?.label) && "service-blue-bg"} 
                                                                        ${t(service?.parent_area_name) === (AreaNameOptions?.[1]?.label || "Sanitäranlagen") && "service-yellow-bg"} 
                                                                        ${t(service?.parent_area_name) === AreaNameOptions?.[2]?.label && "service-green-bg"}
                                                                        ${service?.object === "WC" && "service-orange-bg"}
                                                                        ${service?.object === "Urinal" && "service-orange-bg"}`}>
                                                                        {service?.object}
                                                                    </td>

                                                                    <td className='task'>{service?.task}</td>

                                                                    <td className='frequency'>{`${service?.frequency > 0 ? service?.frequency+" x "+ t("Weekly") : t("As needed")} `}</td>
                                                                    {shortWeekdays.map((day, dayIndex) => (
                                                                        <td key={dayIndex} className='text-center'>{/* {console.log("service[day.toLowerCase()]", service[day])} */}
                                                                            {service[t(day)] ? (
                                                                                <Icon image attrIcon={App_url.img.GreenCheckboxIcon} />
                                                                            ) : null}
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </React.Fragment>
                                                ))}
                                            </table>
                                        </div>
                                    ))}

                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </div>
        </ContentLayout>
    )
}
