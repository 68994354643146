/* eslint-disable */
import React, { useContext, useEffect } from 'react'
import { useTranslation } from '../../../translate';
import { WebSocketContext } from '../../../WSContext';
import App_url from '../../Common/Constants';
import CustomTableTwo from '../../Common/CustomTableTwo';
import Icon from '../../Common/Icon';
import { useSelector } from 'react-redux';
import Utils from '../../Common/Utils';

const OffersList = () => {
    const { t, changeLanguage } = useTranslation();

    const { ws, send } = useContext(WebSocketContext);
    const { offerOverviewList, offerOverviewDashboard } = useSelector(App_url.externalOfferReducers);
    const { customerDetails } = useSelector(App_url.customerReducers);
    const { lang } = useSelector(App_url.settingReducers)
    // console.log("lang",lang);


    const callOfferOverviewListApi = (page) => {
        const param = {
            transmit: "broadcast",
            url: "offer_overview_list",
            request: {
                page_no: page,
                limit: "20",
                search: "",
                postal_code: "",
                status: "",
                from_date: "",
                to_date: "",
                customer_id: customerDetails[0]?.id,
            }
        };
        // console.log(param)
        send(param);
    };
    useEffect(() => {
        if (ws) {
            callOfferOverviewListApi(1);
        }
    }, [ws])

    const RenderBody = (item, index) => {
        // console.log(item)
        return (
            <React.Fragment>
                <td onClick={() => handleView(item)}><span className={`
                ${item?.status === "Open" && "service-blue-bg"} 
                ${(item?.status === "Reject" || item?.status === "Rejected") && "service-red-bg"} 
                ${(item?.status === "Close" || item?.status === "Closed") && "service-green-bg"}`}>{index + 1}</span></td>
                <td className='new-calc-td'>{item?.offer_code}</td>
                <td className='new-calc-td'>{Utils.formatDateTime2(item?.created_at, t)}</td>
                <td className='new-calc-td'>{item?.offer_type}</td>
                <td className='new-calc-td'>{item?.company_name ? item?.company_name : "-"}</td>
                <td className='new-calc-td'>{item?.phone_number ? item?.phone_number : "-"}</td>
                <td className='new-calc-td'>{item?.first_name ? item?.first_name : "-"}</td>
                <td className='new-calc-td'>{Utils?.trimDecimal(item.total_yearly_cost, 2)}</td>
                <td className='new-calc-td' >{item?.status} </td>
                <td className='new-calc-td'><span className='view-icon-box ' /* onClick={() => handleView(item)} */><Icon className={"bg-primary"} attrIcon={App_url.img.EyeIcon} /></span></td>
            </React.Fragment>
        )
    }
    return (
        <div className="offer-overview">
            <CustomTableTwo
                title={{ text: t("Offer Overview"), size: "fs-20", textTwo: `${t("Total Offers")} ${offerOverviewList?.data ? offerOverviewList?.data?.length : "0"}` }}
                columnNames={[t("Sr.No."), t('Offer ID'), t("Offer Date"), t("Offer Type"), t('Company Name'), t('Mobile No.'), t('Person Name'), t("Yearly"), t("Status"), t("View")]}
                recordData={offerOverviewList?.data}
                renderBody={RenderBody}
                // callBack={(e, state) => callback(e, state, "addDocument")}
                search_placeholder={t("Search by Company,Email, ID")}
                pagination={offerOverviewList?.pagination}
                onPagination={(page) => callOfferOverviewListApi(page)}
                typePlaceHolder={"Select Status"}
            />
        </div>
    )
}

export default OffersList