/* eslint-disable */
import React, { useContext, useEffect, useState } from 'react'
import ContentLayout from '../layout/ContentLayout'
import { Breadcrumb, Card, Table } from 'react-bootstrap'
import AnchorLink from '../Common/AnchorLink'
import App_url from '../Common/Constants'
import Button from '../Common/Button'
import CommonTableComp from './CommonTableComp'
import CommonTbl2 from './CommonTbl2'
import OfferModal from './OfferModal'
import { redirect, useLocation, useNavigate, useParams } from 'react-router-dom'
import { WebSocketContext } from '../../WSContext'
import { useDispatch, useSelector } from 'react-redux'
import wsSendRequest from '../../socketIO/wsSendRequest'
import Icon from '../Common/Icon'
import Utils from '../Common/Utils'
import UtilsGetList from '../Common/UtilsGetList'
import { toast } from 'react-toastify'
import { setStoreDiscardChangesModal, setStoreOfferModal, setStoreValidationMessageModal } from '../../store/modules/modals/action'
import { setStoreServiceSummary } from '../../store/modules/calculation/action'
import { useTranslation } from '../../translate'
import { setStoreCustomersDetails } from '../../store/modules/customers/action'


const CalculationOverview = ({ }) => {
    const { t, changeLanguage } = useTranslation();

    const { ws, send } = useContext(WebSocketContext);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { access_token, device_id, websocketReqRes } = useSelector(App_url.userDataReducers);
    const { calculationOverviewList, frequencyList } = useSelector(App_url.calculationReducers);
    const { customerDetails } = useSelector(App_url.customerReducers);
    const { lang } = useSelector(App_url.settingReducers);
    const [offerContent, setOfferContent] = useState([]);
    console.log("offerContent", offerContent)
    const { id: calculationId } = useParams();

    const location = useLocation();
    const state = location?.state


    const handleDeleteServiceModal = (itemId) => {
        dispatch(setStoreDiscardChangesModal({
            show: "DISCARD_CHANGES_MODAL",
            data: { description: t("Are you sure you want to delete this service ?") },
            callBackModal: () => handleDeleteService(itemId),
        }))
    }
    const handleNavigate = (calculationId, customerId) => {
        navigate(`${App_url.link.ProspectsEdit}/${customerId}`, { state: { fromPage: "calculationOverview", pageId: calculationId, customerId: customerId } })
    }

    const handleViewModal = (calculationId, customerId) => {
        const missingFields = [];
        if (customerDetails?.[0]?.email === "" || customerDetails?.[0]?.email === null) {
            // if (customerDetails?.[0]?.email === "" || customerDetails?.[0]?.email === null) {
            //     missingFields.push("email");
            // }
            // if (customerDetails?.[0]?.customer_name === "" || customerDetails?.[0]?.customer_name === null) {
            //     missingFields.push("customer name");
            // }
            // if (customerDetails?.[0]?.company_address === "" || customerDetails?.[0]?.company_address === null) {
            //     missingFields.push("company address");
            // }
            // if (customerDetails?.[0]?.street_no === "" || customerDetails?.[0]?.street_no === null) {
            //     missingFields.push("street number");
            // }
            // if (customerDetails?.[0]?.city === "" || customerDetails?.[0]?.city === null) {
            //     missingFields.push("city");
            // }
            // if (customerDetails?.[0]?.postal_code === "" || customerDetails?.[0]?.postal_code === null) {
            //     missingFields.push("postal code");
            // }
            // if (
            //     (customerDetails?.[0]?.company_mobile === "" || customerDetails?.[0]?.company_mobile === null) &&
            //     (customerDetails?.[0]?.mobile === "" || customerDetails?.[0]?.mobile === null)
            // ) {
            //     missingFields.push("phone number");
            // }

            // const missingFieldsMessage = missingFields.length > 0
            //     ? t("The information of selected customer is incomplete, please update customer information.")
            //     : "";
            const hasMissingFields =
                customerDetails?.[0]?.email === null || customerDetails?.[0]?.email === "" ||
                customerDetails?.[0]?.customer_name === null || customerDetails?.[0]?.customer_name === "" ||
                customerDetails?.[0]?.company_address === null || customerDetails?.[0]?.company_address === "" ||
                customerDetails?.[0]?.street_no === null || customerDetails?.[0]?.street_no === "" ||
                customerDetails?.[0]?.city === null || customerDetails?.[0]?.city === "" ||
                customerDetails?.[0]?.postal_code === null || customerDetails?.[0]?.postal_code === "" ||
                (customerDetails?.[0]?.company_mobile === null || customerDetails?.[0]?.company_mobile === "") &&
                (customerDetails?.[0]?.mobile === null || customerDetails?.[0]?.mobile === "");

            const missingFieldsMessage = hasMissingFields
                ? t("The information of selected customer is incomplete, please update customer information.")
                : "";


            // UtilsGetList.callCustomerDetailsApi({ ws, customer_id: customerId, lang: lang });
            dispatch(setStoreValidationMessageModal({
                show: "VALIDATION_MESSAGE_MODAL",
                title: t("Validation Message"),
                description: missingFieldsMessage,
                callBackModal: () => handleNavigate(calculationId, customerId),
            }));
        }
        else {
            if (customerId && customerId !== "None") {
                dispatch(setStoreCustomersDetails())
                UtilsGetList.callCustomerDetailsApi({ ws, customer_id: customerId, lang: lang })
                dispatch(setStoreOfferModal({
                    show: "OFFER_MODAL",
                    data: { calculationId, customerId, offerContent, overview_id: state?.overview_id, fromPage: "calculation" }
                }));
            }
            else {
                navigate(App_url?.link?.selectCustomer, { state: { offerContent, calculationId: calculationId, overview_id: state?.overview_id } })
            }
        }

    }


    const handleDeleteService = (itemId) => {
        const param = {
            transmit: "broadcast",
            url: "service_delete",
            request: { service_id: itemId },
            toast: true,
        }
        if (calculationOverviewList?.length === 1) {
            param.delete = {
                calculation_id: calculationId,
            }
        }
        send(param);
    }

    const handleEditService = (service) => {
        console.log("service==", service);
        if (service?.category_id === App_url.ids.glassCleaningID) {
            navigate(`${App_url.link.newCalculationGlassCleaning}/${service?.id}`, { state: { service } });
        }
        if (service?.category_id === App_url.ids.fullCleaningID) {
            navigate(`${App_url.link.newCalculationFullCleaning}/${service?.id}`, { state: { service } });
        }
        if (service?.category_id === App_url.ids.maintenanceCleaningID) {
            navigate(`${App_url.link.newCalculationMaintenanceCleaning}/${service?.id}`, { state: { service } });
        }
        if (service?.category_id === App_url.ids.intervalCleaningID) {
            dispatch(setStoreServiceSummary());
            callServiceSummaryApi(service);
            navigate(`${App_url.link.newCalculationIntervalCleaning}/${service?.id}`, { state: { service } });
        }
        if (service?.category_id === App_url.ids.buildingDivisionID) {
            dispatch(setStoreServiceSummary());
            callServiceSummaryApi(service);
            navigate(`${App_url.link.newCalculationBuildingDivision}/${service?.id}`, { state: { service } });
        }
    }

    const callServiceSummaryApi = (service) => {
        const param = {
            transmit: "broadcast",
            url: "get_service_summary",
            request: { summary_id: service?.id }
        };
        send(param);
    }


    const handleAddMoreCalculations = () => {
        console.log("calculationOverviewList", calculationOverviewList)
        if(calculationOverviewList?.[0]?.customer_id){
            navigate(`${App_url.link.selectCategory}/${calculationOverviewList?.[0]?.customer_id}`, { state: calculationId })
        }else{
            navigate(`${App_url.link.selectCategory}/proceedWithoutCustomer`, { state: calculationId })
        }
    }
    
    const handleFrequency = (frequency) => {
        const itemFrequency = frequencyList?.data?.find(item => item?.no_of_days === frequency.toString());
        return t(itemFrequency?.frequency_name) || 0;
    }

    useEffect(() => {
        const fetchData = async () => {
            const offerContent = await UtilsGetList.getOfferContent({ access_token, lang:lang });
            setOfferContent(offerContent);
        }
        fetchData();
    }, [])
    const handleClick = () => {
        navigate(App_url.link.savedCalculation)
    }

    // useEffect(()=>{
    //      if(websocketReqRes?.url === "service_delete"){
    //         UtilsGetList.callGetCalculationListApi({ws, device_id, calculationId:calculationId});
    //     }if(calculationOverviewList?.length === 0){
    //         console.log("calculationOverviewList", calculationOverviewList?.length)
    //     }
    // },[websocketReqRes?.url, calculationOverviewList?.length])

    return (
        <ContentLayout title={t('Calculation Overview')}>
            <div className='overview'>
                <Breadcrumb className="breadcrumb-main">
                    <Breadcrumb.Item>
                        <AnchorLink to={App_url.link.savedCalculation}>{t("Saved Calculation")} </AnchorLink>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item active>{t("Calculation Overview")}</Breadcrumb.Item>
                </Breadcrumb>
                <Card className='card rounded-3 calculation-overview-card'>
                    <Card.Header>
                        <div className='card-body-header'>
                            <div className='.item1  .flex-item'>
                                <h6 className='card-body-header-title'>{t("Calculation Overview")}</h6>
                            </div>
                            <div className='btns'>
                                <Button onClick={() => navigate(App_url.link.savedCalculation)} size={'md'} className={'save-btn me-2'}>{t("Saved Calculation")}</Button>
                                {/* <Button onClick={()=> navigate(App_url.link.addToOffer, {state: calculationId})}size={'md'} className={""} variant={"primary"} >Add To Offer</Button> */}
                                <Button disable={offerContent?.length === 0} onClick={() => handleViewModal(calculationId, calculationOverviewList?.[0]?.customer_id)} size={'md'} className={""} variant={"primary"} >{t("Add To Offer")}</Button>
                                <Button onClick={handleAddMoreCalculations} size={'md'} className={"ms-2"} variant={"outline-primary"}>{t("+Add More")}</Button>
                            </div>
                        </div>
                    </Card.Header>
                    <div className='heading'>
                        <h6>{t("Customer")} : {calculationOverviewList?.[0]?.customer_name} </h6>
                    </div>
                    <Card.Body>
                        {calculationOverviewList?.length > 0 && calculationOverviewList?.map((item) => (
                            <div className='custom-table-overview'>
                                <table className='table table-bordered'>
                                    <thead className=''>
                                        <tr>
                                            <th className={"header-1"}>{t(item?.category_name)}</th>
                                            <th className={"header-2"} colSpan={3}>
                                                <div className='d-flex justify-content-between'>
                                                    <span> {t("Man Hour Cost Calculation")}</span>
                                                    {(item?.category_id !== App_url.ids.glassCleaningID || item?.additional_cost?.length === 0) &&
                                                        <div>
                                                            <Icon onClick={() => handleEditService(item)} className={"bg-white me-2 cursor-pointer"} attrIcon={App_url.img.EditIcon} />
                                                            <Icon onClick={() => handleDeleteServiceModal(item?.id)} className={"bg-white cursor-pointer"} attrIcon={App_url.img.DeleteIcon} />
                                                        </div>
                                                    }
                                                </div>
                                            </th>
                                            {item?.additional_cost?.length > 0 &&
                                                <th className={"header-2"} colSpan={3}>
                                                    <div className='d-flex justify-content-between'>
                                                        <span> {t("Additional Cost Calculation")}</span>
                                                        <div>
                                                            <Icon onClick={() => handleEditService(item)} className={"bg-white me-2 cursor-pointer"} attrIcon={App_url.img.EditIcon} />
                                                            <Icon onClick={() => handleDeleteServiceModal(item?.id)} className={"bg-white cursor-pointer"} attrIcon={App_url.img.DeleteIcon} />
                                                        </div>
                                                    </div>
                                                </th>
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='sub-header-1'>
                                            {item?.category_id === App_url.ids.glassCleaningID ? <th className='ps-4 pe-0'>{t(item?.service_name)}</th> : <th className='ps-4 pe-0'>{t("Surface Area")}: {t(item?.surface_area)} {t("Meter Square")}</th>}
                                            <th className='text-center'>{t("Particular")}</th>
                                            <th className='text-center'>{t("Hours")}</th>
                                            <th className='text-center'>{t("Cost")}</th>
                                            {item?.additional_cost?.length > 0 &&
                                                <React.Fragment>
                                                    <th className='text-center'>{t("Particular")}</th>
                                                    <th className='text-center'>{t("Frequency")}</th>
                                                    <th className='text-center'>{t("Cost")}</th>
                                                </React.Fragment>
                                            }
                                        </tr>
                                        {item?.category_id === App_url.ids.glassCleaningID && <React.Fragment>
                                            <tr className='content'>
                                                <td rowSpan={2}>
                                                    <div className='mt-1 ps-3'>
                                                        <Icon image attrIcon={App_url.img.SurfaceIcon} /> <span>{t("Surface Area")}: {item?.surface_area} </span>
                                                    </div> <br />
                                                    <div className='mb-1 ps-3'>
                                                        <Icon image attrIcon={App_url.img.CalendarIcon3} /> <span>{t("Frequency")} : {handleFrequency(item?.frequency)}</span>
                                                    </div>
                                                </td>
                                                <td rowSpan={2}><div className='mt-1 text-center'>{t("Each")}</div><br /> <div className='mb-1 text-center'> {t("Yearly")} </div></td>
                                                <td rowSpan={2}><div className='mt-1 text-end'>{Utils.trimDecimal(item?.per_exceution_hour, 2)} </div><br /> <div className='mt-1 text-end'>{Utils.trimDecimal(item?.yearly_hour, 2)}</div></td>
                                                <td rowSpan={2}><div className='mt-1 text-end'>€ {Utils.trimDecimal(item?.per_exceution, 2)}</div><br /><div className='mt-1 text-end'>€ {Utils.trimDecimal(item?.yearly_cost, 2)}</div></td>

                                                {item?.additional_cost?.length > 0 && (
                                                    <>
                                                        <td className='text-center'>{t("Scaffold")}</td>
                                                        <td className='text-center'>{Utils.trimDecimal(item?.additional_cost?.find(cost => cost.type === 'scaffold')?.no_of_operations_per_year || 0, 2)}</td>
                                                        <td className='text-center'>€ {Utils.trimDecimal(item?.additional_cost?.find(cost => cost.type === 'scaffold')?.cost_per_operation || 0, 2)}</td>
                                                    </>
                                                )}
                                            </tr>

                                            {item?.additional_cost?.length > 0 &&
                                                <React.Fragment>
                                                    <tr className='content'>
                                                        <td className='text-center'>{t("Material")}</td>
                                                        <td className='text-center'>{Utils.trimDecimal(item?.additional_cost?.find(cost => cost.type === 'material')?.no_of_operations_per_year || 0, 2)}</td>
                                                        <td className='text-center'>€ {Utils.trimDecimal(item?.additional_cost?.find(cost => cost.type === 'material')?.cost_per_operation || 0, 2)}</td>
                                                    </tr>
                                                </React.Fragment>
                                            }
                                            {item?.additional_cost?.length > 0 &&
                                                <React.Fragment>
                                                    <tr className='content'>
                                                        <td></td>
                                                        <td colSpan={3}></td>
                                                        <td className='text-center'>{t("Misc")}</td>
                                                        <td className='text-center'>{Utils.trimDecimal(item?.additional_cost?.find(cost => cost.type === 'miscellaneous')?.no_of_operations_per_year || 0, 2)}</td>
                                                        <td className='text-center'>€ {Utils.trimDecimal(item?.additional_cost?.find(cost => cost.type === 'miscellaneous')?.cost_per_operation || 0, 2)}</td>
                                                    </tr>
                                                </React.Fragment>
                                            }
                                        </React.Fragment>}
                                        {(item?.category_id === App_url.ids.fullCleaningID ||
                                            item?.category_id === App_url.ids.intervalCleaningID ||
                                            item?.category_id === App_url.ids.buildingDivisionID ||
                                            item?.category_id === App_url.ids.maintenanceCleaningID
                                        ) &&
                                            <React.Fragment>
                                                <tr className='content'>
                                                    <td rowSpan={4}></td>
                                                    <td className='text-center'>{t("Sunday")}</td>
                                                    <td className='text-center'>{Utils.trimDecimal(item?.sunday_hour, 2)}</td>
                                                    <td className='text-center'>€  {Utils.trimDecimal(item?.sunday_cost, 2)}</td>
                                                </tr>
                                                <tr className='content'>
                                                    <td className='text-center'>{t("Weekday")}</td>
                                                    <td className='text-center'>{Utils.trimDecimal(item?.weekday_hour, 2)}</td>
                                                    <td className='text-center'>€  {Utils.trimDecimal(item?.weekday_cost, 2)}</td>
                                                </tr>
                                                <tr className='content'>
                                                    <td className='text-center'>{t("Monthly")}</td>
                                                    <td className='text-center'>{Utils.trimDecimal(item?.monthly_hour, 2)}</td>
                                                    <td className='text-center'>€  {Utils.trimDecimal(item?.monthly_cost, 2)}</td>
                                                </tr>
                                                <tr className='content'>
                                                    <td className='text-center'>{t("Yearly")}</td>
                                                    <td className='text-center'>{Utils.trimDecimal(item?.yearly_hour, 2)}</td>
                                                    <td className='text-center'>€  {Utils.trimDecimal(item?.yearly_cost, 2)}</td>
                                                </tr>
                                            </React.Fragment>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        ))}
                    </Card.Body>
                </Card>
            </div>
            {/* <OfferModal isModalOpen={isModalOpen} handleClose={closeModal} /> */}
        </ContentLayout>
    )
}

export default CalculationOverview