
export const PostRequestAPI = async (apiurl, payload, access_token,lang, contentType, responseType,pdf) => {
    const headers = {
      'Accept':"application/json",
      // ...(lang && { lang: lang })

    }
    if(access_token){
      headers.Authorization = `Bearer ${access_token}`
    }
    if(contentType){
      headers['Content-Type']='multipart/form-data'
    }
    if(pdf){
      headers.Accept = "text/html"
    }
    const contentMethod = {
      headers:headers,
    }
    if(responseType){
      contentMethod.responseType = responseType
    }
    const getResponse = window.axios.post(`${process.env.REACT_APP_API_DOMAIN_URL}/${apiurl}`, payload, contentMethod).then(function (result) {
      return result;
    }).catch((e)=>e.response);
    return getResponse;
};
