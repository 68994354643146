/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Editor } from "primereact/editor";
import { useTranslation } from "../../../translate";
import { useSelector } from "react-redux";
import App_url from "../Constants";

// const replaceImageSrc = (htmlString, inlineIds) => {
//   // Regular expression to match src="cid:..."
//   const regex = /src="cid:([^"]*)"/g;

//   // Replace each matched src="cid:..." with the corresponding base64 content
//   const replacedHtmlString = htmlString?.replaceAll(regex, (match, cid) => {
//     // Find the corresponding object in inlineIds by matching the cid

//     const inlineObj = inlineIds.find(obj => obj.content_id === cid);
//     if (inlineObj) {
//       // Get the content bytes and content type
//       const { content_type, content_bytes } = inlineObj;
//       // Replace with the base64 content and appropriate content type
//       return `src="data:${content_type};base64,${content_bytes}"`;
//     }

//     // If no matching cid is found, return the original match
//     return match;
//   });
//   return replacedHtmlString;
// };
export default function PrimeReactPage({ handleQuillData, refs, initialTemplate }) {
  const { t, changeLanguage, } = useTranslation();
  const { lang } = useSelector(App_url.settingReducers);
  const { ModalPopup } = useSelector(App_url.modalReducers);
  const editorRef = useRef(null);
  const [text, setText] = useState('');
  const [descr, setDescr] = useState('')
  const [inlineIds, setInlineIds] = useState([]);

  console.log("ModalPopup?.data?.fromPage", ModalPopup?.data?.fromPage)


  // Replace inline image src in the HTML string
  const replaceImageSrc = (htmlString, inlineIds) => {
    const regex = /src="cid:([^"]*)"/g;

    return htmlString.replace(regex, (match, cid) => {
      const inlineObj = inlineIds?.find(obj => obj?.content_id === cid);
      if (inlineObj) {
        const { content_type, content_bytes } = inlineObj;
        return `src="data:${content_type};base64,${content_bytes}"`;
      }
      return match; // Keep original if no match is found
    });
  };

  // Fetch inline images and prepare inlineIds if fromPage === "viewPromotion"
  useEffect(() => {
    if (ModalPopup?.data?.fromPage === "viewPromotion") {
      const promotion = ModalPopup?.data?.promotionDocs[0];

      // Filter attachments with is_inline === true
      const inlineDocs = promotion?.attachment?.filter(doc => doc?.is_inline === true);

      // Prepare inlineIds by mapping necessary fields
      const processedInlineIds = inlineDocs?.map(doc => ({
        content_id: doc?.content_id, // The CID for matching
        content_type: doc?.content_type, // Image MIME type
        content_bytes: doc?.content_bytes, // Base64 image data
      }));
      setInlineIds(processedInlineIds);
      // Get the description for the promotion if available
      if (promotion?.description) {
        setDescr(promotion?.description); // Set the description from the promotion
      }
    }
  }, [ModalPopup]);

  // Process the description template with inline images (only when fromPage is "viewPromotion")
  useEffect(() => {
    if (ModalPopup?.data?.fromPage === "viewPromotion" && inlineIds?.length > 0) {
      const replacedHtml = replaceImageSrc(descr, inlineIds);
      setText(replacedHtml);
    } else {
      setText(initialTemplate);
    }
  }, [ModalPopup, descr, inlineIds, initialTemplate]);

  // Initialize Quill editor with the appropriate content (processed or raw)
  useEffect(() => {
    const interval = setInterval(() => {
      if (editorRef.current) {
        const quill = editorRef?.current?.getQuill();
        if (quill) {
          if (quill.clipboard) {
            let contentToPaste = "";

            if (ModalPopup?.data?.fromPage === "viewPromotion" && inlineIds?.length > 0) {
              // Paste processed content with inline images
              contentToPaste = replaceImageSrc(descr, inlineIds);
            } else {
              // Paste raw initialTemplate
              contentToPaste = initialTemplate;
            }
            // Make sure the content is properly rendered
            quill.clipboard.dangerouslyPasteHTML(contentToPaste);
            clearInterval(interval);
          }
        }
      }
    }, 100);
    return () => clearInterval(interval);
  }, [descr, inlineIds, initialTemplate]);

  const renderHeader = () => {
    return (
      <span className="ql-formats">
        <button ref={el => refs.current.refBold = el} className="ql-bold" aria-label="Bold"></button>
        <button ref={el => refs.current.refItalic = el} className="ql-italic" aria-label="Italic"></button>
        <button ref={el => refs.current.refUnderline = el} className="ql-underline" aria-label="Underline"></button>
        <button ref={el => refs.current.refOrderedList = el} className="ql-list" value="ordered" aria-label="Ordered List"></button>
        <button ref={el => refs.current.refUnOrderedList = el} className="ql-list" value="bullet" aria-label="Unordered List"></button>
        <button ref={el => refs.current.refImage = el} className="ql-image" aria-label="Image"></button>
        <button ref={el => refs.current.refLink = el} className="ql-link" aria-label="Link"></button>
      </span>
    );
  };

  const header = renderHeader()

  const handleTextChange = (e) => {
    setText(e.htmlValue);
    handleQuillData(e.htmlValue);
  };
  return (
    <div className="card pr mb-3">
      <Editor className='primeEditor' placeholder={`${t("Type here...")}`} value={text} onTextChange={handleTextChange} ref={editorRef} headerTemplate={header} style={{ height: 'auto' }} />
    </div>
  )
}